(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('AgregarSocioSinFacturaController', AgregarSocioSinFacturaController);


    AgregarSocioSinFacturaController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService'];

    function AgregarSocioSinFacturaController ($translate, $timeout, Auth, LoginService) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
		vm.registerSinFactura = registerSinFactura;
        vm.registerAccount = {};
        vm.celular = {};
        vm.success = null;
       

        $timeout(function (){angular.element('#login').focus();});

       
        
        function registerSinFactura () {
            
        	if (vm.registerAccount.email !== vm.confirmEmail) {
                vm.doNotMatchCorreo = 'ERROR';
            }else {
            	vm.registerAccount.celular=vm.celular.code+""+vm.celular.numero;
                vm.registerAccount.langKey = $translate.use();
                vm.registerAccount.authorities=['ROLE_USER'];
                vm.registerAccount.login=vm.registerAccount.dni;
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.registerAccount.password= vm.registerAccount.dni;

                Auth.createAccountSinFactura(vm.registerAccount).then(function () {
                    vm.success = 'OK';
       				 vm.registerAccount = {};
						vm.celular = {};

                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'El interno y codigo de pago no coincide con el Cliente') {
                        vm.errorValidData = 'ERROR'; 
                    }
                    else {
                        vm.error = 'ERROR';
                    }
                });
            }
       
    }
    }
})();

(function() {
	'use strict';

	angular.module('coopFrontEndApp').controller('ImporterDialogController',
			ImporterDialogController).directive('fileModel',
			[ '$parse', function($parse) {
				return {
					restrict : 'A',
					link : function(scope, element, attrs) {
						var model = $parse(attrs.fileModel);
						var modelSetter = model.assign;
						element.bind('change', function() {
							scope.$apply(function() {
								modelSetter(scope, element[0].files[0]);
							});
						});
					}
				};
			} ]);

	ImporterDialogController.$inject = [ '$timeout', '$scope', '$stateParams',
			'$uibModalInstance', 'Importer','ImporterGrandes','ImporterEnergia','ImporterDeuda','ImporterPaga','ImporterHogares' ];

	function ImporterDialogController($timeout, $scope, $stateParams,
			$uibModalInstance, Importer,ImporterGrandes,ImporterEnergia,ImporterDeuda,ImporterPaga,ImporterHogares) {
		var vm = this;
		vm.clear = clear;
		vm.save = save;
		vm.saveGrandes = saveGrandes;
		vm.saveEnergia = saveEnergia;
		vm.saveHogares = saveHogares;
		vm.savePaga = savePaga;
		vm.saveDeuda = saveDeuda;

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
		    
			var fd = new FormData();
			fd.append('file', document.getElementById('file').files[0]);

			Importer.upload(fd).$promise.then(function (res) {
				alert('Facturación subida exitosamente!');
				$uibModalInstance.dismiss('cancel');
	        }).catch(function (err) {
	        	alert('Ocurrio un error al subir la Facturación!');
	            throw err;
	        });
		}
		
		function saveEnergia() {
			vm.isSaving = true;
		    
			var fd = new FormData();
			fd.append('file', document.getElementById('file').files[0]);

			ImporterEnergia.upload(fd).$promise.then(function (res) {
				alert('Facturación subida exitosamente!');
				$uibModalInstance.dismiss('cancel');
	        }).catch(function (err) {
	        	alert('Ocurrio un error al subir la Facturación!');
	            throw err;
	        });
		}
		
		function saveGrandes() {
			vm.isSaving = true;
		    
			var fd = new FormData();
			fd.append('file', document.getElementById('file').files[0]);

			ImporterGrandes.upload(fd).$promise.then(function (res) {
				alert('Facturación subida exitosamente!');
				$uibModalInstance.dismiss('cancel');
	        }).catch(function (err) {
	        	alert('Ocurrio un error al subir la Facturación!');
	            throw err;
	        });
		}
		
		function saveHogares() {
			vm.isSaving = true;
		    
			var fd = new FormData();
			fd.append('file', document.getElementById('file').files[0]);

			ImporterHogares.upload(fd).$promise.then(function (res) {
				alert('Facturación subida exitosamente!');
				$uibModalInstance.dismiss('cancel');
	        }).catch(function (err) {
	        	alert('Ocurrio un error al subir la Facturación!');
	            throw err;
	        });
		}
		
		
		function saveDeuda() {
			vm.isSaving = true;
		    
			var fd = new FormData();
			fd.append('file', document.getElementById('file').files[0]);

			ImporterDeuda.upload(fd).$promise.then(function (res) {
				alert('Arichvo de deuda subido exitosamente!');
				$uibModalInstance.dismiss('cancel');
	        }).catch(function (err) {
	        	alert('Ocurrio un error al subir el Arichvo de deuda!');
	            throw err;
	        });
		}
		
		function savePaga() {
			vm.isSaving = true;
		    
			var fd = new FormData();
			fd.append('file', document.getElementById('file').files[0]);

			ImporterPaga.upload(fd).$promise.then(function (res) {
				alert('Arichvo de Pagos subido exitosamente!');
				$uibModalInstance.dismiss('cancel');
	        }).catch(function (err) {
	        	alert('Ocurrio un error al subir el Arichvo de Pagos!');
	            throw err;
	        });
		}
		
	}
})();








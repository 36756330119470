(function() {
    'use strict';
    angular.module('coopFrontEndApp').factory('AsociarSuministro', AsociarSuministro).factory('DesvincularSuministro',DesvincularSuministro);

    AsociarSuministro.$inject = [ '$resource' ];
    DesvincularSuministro.$inject = [ '$resource' ];
    var resourceAsociarSuministro = 'importerv3/' + 'api/cliente/asociar?numeroInterno=:numeroInterno&codigoPago=:codigoPago';
    var resourceDesvincularSuministro = 'importerv3/' + 'api/cliente/desvincular?numeroInterno=:numeroInterno';

    function AsociarSuministro($resource) {
        return $resource(resourceAsociarSuministro, {
            numeroInterno : "@numeroInterno",
            codigoPago : "@codigoPago"
        }, {
            'asociar' : {
                method : 'POST',
                timeout: 0,
                transformRequest : angular.identity,
                transformResponse : function(data) {
                    return {
                        responseData : data.toString()
                    };
                }
            }
        });
    }

    function DesvincularSuministro($resource) {
        return $resource(resourceDesvincularSuministro, {
            numeroInterno : "@numeroInterno"
        }, {
            'desvincular' : {
                method : 'POST',
                timeout: 0,
                transformRequest : angular.identity,
                transformResponse : function(data) {
                    return {
                        responseData : data.toString()
                    };
                }
            }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('ClienteFacturaPdf', ClienteFacturaPdf).factory('HogaresPdf', HogaresPdf).factory('HogaresAdminPdf', HogaresAdminPdf).factory('FacturaPDF', FacturaPDF).factory('FacturaGrandesPDF', FacturaGrandesPDF).factory('FacturaEnergiaPDF', FacturaEnergiaPDF).factory('FacturaAguaPDF', FacturaAguaPDF);

    ClienteFacturaPdf.$inject = ['$resource'];
    FacturaPDF.$inject = ['$resource'];
    FacturaEnergiaPDF.$inject = ['$resource'];
    FacturaAguaPDF.$inject = ['$resource'];
    FacturaGrandesPDF.$inject = ['$resource'];
    HogaresPdf.$inject = ['$resource'];
    HogaresAdminPdf.$inject = ['$resource'];

    function FacturaPDF ($resource) {
        var resourceUrl =  'coopreports/' + 'api/admin/factura/pdf';
        return $resource(resourceUrl, {}, {
            'post': {
            	method: 'post',
            	headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
                cache: true,
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }                
            }
        });
    }
    
    function ClienteFacturaPdf($resource) {
    	var resourceUrl =  'coopreports/' + 'api/cliente/factura/hogares/pdf';
		return $resource(resourceUrl, {
			facturaId : "@facturaId"
		}, {
			'descargar' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }
			}
		});
	}
    
    function HogaresAdminPdf($resource) {
    	var resourceUrl =  'coopreports/' + 'api/admin/factura/hogares/pdf';
		return $resource(resourceUrl, {
			facturaId : "@facturaId"
		}, {
			'descargar' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }
			}
		});
	}
    
    function HogaresPdf($resource) {
    	var resourceUrl =  'coopreports/' + 'api/cliente/facturaDos/hogares/pdf';
		return $resource(resourceUrl, {
			facturaId : "@facturaId"
		}, {
			'descargar' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }
			}
		});
	}
    
    function FacturaEnergiaPDF($resource) {
    	var resourceUrl =  'coopreports/' + 'api/cliente/factura/grandes-clientes/energia/pdf';
		return $resource(resourceUrl, {
			facturaId : "@facturaId"
		}, {
			'descargar' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }
			}
		});
	}
    
    function FacturaAguaPDF($resource) {
    	var resourceUrl =  'coopreports/' + 'api/cliente/factura/grandes-clientes/agua/pdf';
		return $resource(resourceUrl, {
			facturaId : "@facturaId"
		}, {
			'descargar' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }
			}
		});
	}
    
    function FacturaGrandesPDF($resource) {
    	var resourceUrl =  'coopreports/' + 'api/cliente/factura/grandes-clientes/nueva/pdf';
		return $resource(resourceUrl, {
			facturaId : "@facturaId"
		}, {
			'descargar' : {
				method : 'POST',
				timeout: 100000000000,
				transformRequest : angular.identity,
				responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }
			}
		});
	}
    
    function ClienteFacturaPdf2 ($resource) {
        var resourceUrl =  'coopreports/' + 'api/cliente/factura/hogares/pdf';
        return $resource(resourceUrl, {facturaId : "@facturaId"}, {
            'post': {
            	method: 'post',
            	headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
                cache: true,
				transformRequest : angular.identity,
                transformResponse: function (data) {
                    var pdf;
                    if (data) {
                        pdf = new Blob([data], {
                            type: 'application/pdf'
                        });
                    }
                    return {
                        response: pdf
                    };
                }                
            }
        });
    }
})();

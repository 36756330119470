(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('AdministrarSuministrosController', AdministrarSuministrosController);

    AdministrarSuministrosController.$inject = ['$scope','Principal', 'ClienteInternosGrandesClientes', 'ClienteInternos', 'AlertService', 'previousState'];

    function AdministrarSuministrosController($scope,Principal, ClienteInternosGrandesClientes, ClienteInternos, AlertService, previousState) {
        var vm = this;
        vm.internos = [];
        vm.internosGrandes = [];
        vm.previousState = previousState.name;
        vm.account = null;
        
        getInternos();

        Principal.identity().then(function (account) {
            vm.account = account;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        function getInternos() {

            ClienteInternos.get().$promise.then(
                //success
                function (data) {/*Do something with value*/
                    vm.internos = data;

                },
                //error
                function (error) {/*Do something with error*/ }
            );
        }
    }
})();

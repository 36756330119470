(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('AutogestionController', AutogestionController);

    AutogestionController.$inject = ['$state','$window', '$scope', '$stateParams',  'ClienteFacturaPdf', 'FileSaver','Autogestion','AlertService','previousState','Interno','Cliente','HogaresPdf'];

    function AutogestionController($state,$window, $scope, $stateParams, ClienteFacturaPdf, FileSaver,Autogestion,AlertService,previousState,Interno,Cliente,HogaresPdf) {

        var vm = this;
        vm.interno = null;
        vm.numbers = [];
        vm.clear = clear;
        vm.previousState = previousState.name;
        vm.descargarPdf = descargarPdf

        
        
        loadFacturas();
        
        function loadFacturas() {            

        	Autogestion.get({numero : $stateParams.numero
                
            }, onSuccess, onError);

            function onSuccess(data, headers) {               
                vm.facturas = data;
                vm.ultimaFactura = vm.facturas [0];
                Interno.get({id : vm.ultimaFactura.internoId},onSuccessInterno);
               
            }           
            
            function onSuccessInterno(data) {
                vm.interno = data;
                Cliente.get({id : vm.interno.clienteId},onSuccessSocio);
            }

            function onSuccessSocio(data) {
                vm.socio = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
   


        function clear() {
            vm.numbers = '';
        }


        function descargarPdf(facturaDto) {
            vm.isSending = true;
            
            if (facturaDto.modeloDos){
            	HogaresPdf.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {				
            		var pdfUrl = URL.createObjectURL(res.response); 
                	var printwWindow = $window.open(pdfUrl, 'C-Sharpcorner', 'width=200,height=200');
                     printwWindow.print(); 
	               	        
		        }).catch(function (err) {
					vm.isSending = false;
		        });		
            }else{
            	ClienteFacturaPdf.descargar({ "facturaId": facturaDto.id }).$promise.then(function (res) {
                	var pdfUrl = URL.createObjectURL(res.response); 
                	var printwWindow = $window.open(pdfUrl, 'C-Sharpcorner', 'width=200,height=200');
                     printwWindow.print();
                }).catch(function (err) {
                    vm.isSending = false;
                });
            }
            
            
        }
    }
})();

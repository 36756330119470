(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FormasDePagoController', FormasDePagoController);

        FormasDePagoController.$inject = ['$scope', 'Auth', 'Principal', 'AlertService', 'LoginService', 'ClienteInternos', 'ClienteInternosGrandesClientes', 'ClienteFacturas', '$state'];

    function FormasDePagoController($scope, Auth, Principal, AlertService, LoginService, ClienteInternos, ClienteInternosGrandesClientes, ClienteFacturas, $state) {
        var vm = this;


        vm.account = null;
        vm.isAuthenticated = null;
        vm.logout = logout;

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function logout() {
            Auth.logout();
            $state.go('home');
        }
    }
})();

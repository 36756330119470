// app/controllers/main.controller.js
(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('MainController', MainController);

    MainController.$inject = ['$timeout'];

    function MainController($timeout) {
        var vm = this;
        vm.loading = true;

        // Simular la carga de contenido
        $timeout(function() {
            vm.loading = false;
        }, 3000); // 3 segundos para simular la carga de contenido
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .factory('LoteSearch', LoteSearch);

    LoteSearch.$inject = ['$resource'];

    function LoteSearch($resource) {
        var resourceUrl =  'importerv3/' + 'api/_search/lotes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('Autogestion', Autogestion);

    Autogestion.$inject = ['$resource', 'DateUtils'];

    
    function Autogestion ($resource, DateUtils) {
        var resourceUrl =  'importerv3/' + 'api/cliente/facturas/interno/:numero';
        return $resource(resourceUrl, {}, {
        	'get': { method: 'GET', isArray: true},
        });
    }
})();

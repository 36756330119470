(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('DetalleFacturaMoraDetailController', DetalleFacturaMoraDetailController);

    DetalleFacturaMoraDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DetalleFacturaMora', 'FacturaGrandesClientesAgua', 'FacturaGrandesClientesEnergia'];

    function DetalleFacturaMoraDetailController($scope, $rootScope, $stateParams, previousState, entity, DetalleFacturaMora, FacturaGrandesClientesAgua, FacturaGrandesClientesEnergia) {
        var vm = this;

        vm.detalleFacturaMora = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:detalleFacturaMoraUpdate', function(event, result) {
            vm.detalleFacturaMora = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('DetalleFacturaGrandesClientesDeleteController',DetalleFacturaGrandesClientesDeleteController);

    DetalleFacturaGrandesClientesDeleteController.$inject = ['$uibModalInstance', 'entity', 'DetalleFacturaGrandesClientes'];

    function DetalleFacturaGrandesClientesDeleteController($uibModalInstance, entity, DetalleFacturaGrandesClientes) {
        var vm = this;

        vm.detalleFacturaGrandesClientes = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DetalleFacturaGrandesClientes.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

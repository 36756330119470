(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('ClienteFacturaAdherirController',ClienteFacturaAdherirController);

    ClienteFacturaAdherirController.$inject = ['$uibModalInstance','$scope', 'entity', 'Interno'];

    function ClienteFacturaAdherirController($uibModalInstance,$scope, entity, Interno) {
        var vm = this;

        vm.interno = entity;
        vm.clear = clear;
        vm.confirmAdherir = confirmAdherir;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmAdherir () {
            vm.isSaving = true;
            if (vm.interno.id !== null) {
            	vm.interno.adheridoFactura=true;
                Interno.update(vm.interno, onSaveSuccess, onSaveError);
            } else {
                Interno.save(vm.interno, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('coopFrontEndApp:internoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('notifier', {
            parent: 'admin',
            url: '/notifier?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'coopFrontEndApp.registro.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/notifier/notifier.html',
                    controller: 'NotifierController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('notificador');
                    return $translate.refresh();
                }]
            }
        })
        .state('notifier.send-email', {
            parent: 'notifier',
            url: '/send-email',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/notifier/notifier-send-email-dialog.html',
                    controller: 'NotifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('notifier', null, { reload: 'notifier' });
                }, function() {
                    $state.go('notifier');
                });
            }]
        })
       .state('notifier.send-email-gc', {
            parent: 'notifier',
            url: '/send-email-gc',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/notifier/notifier-send-email-gc-dialog.html',
                    controller: 'NotifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('notifier', null, { reload: 'notifier' });
                }, function() {
                    $state.go('notifier');
                });
            }]
        })
        .state('notifier.send-email-hogares', {
            parent: 'notifier',
            url: '/send-email-hogares',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/notifier/notifier-send-email-hogares-dialog.html',
                    controller: 'NotifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('notifier', null, { reload: 'notifier' });
                }, function() {
                    $state.go('notifier');
                });
            }]
        })
        .state('notifier.send-email-socio', {
            parent: 'notifier',
            url: '/send-email-socio',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/notifier/notifier-send-email-to-socio-dialog.html',
                    controller: 'NotifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('notifier', null, { reload: 'notifier' });
                }, function() {
                    $state.go('notifier');
                });
            }]
        })
       .state('notifier.send-push-socio', {
            parent: 'notifier',
            url: '/send-push-socio',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/notifier/notifier-send-push-socio-dialog.html',
                    controller: 'NotifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('notifier', null, { reload: 'notifier' });
                }, function() {
                    $state.go('notifier');
                });
            }]
        })
        .state('notifier.send-sms', {
            parent: 'notifier',
            url: '/send-sms',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/notifier/notifier-send-sms-dialog.html',
                    controller: 'NotifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                        resolve: {
                            entity: ['CELULARES', function(Celulares) {
                                return Notifier.get_celulares(function(){ vm.isSending = false; }).$promise;
                            }]
                        }
                }).result.then(function() {
                    $state.go('notifier', null, { reload: 'notifier' });
                }, function() {
                    $state.go('notifier');
                });
            }]
        })
        .state('notifier.send-email-fe-erratas', {
            parent: 'notifier',
            url: '/send-fe-erratas',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/notifier/notifier-send-fe-erratas-dialog.html',
                    controller: 'NotifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                }).result.then(function() {
                    $state.go('notifier', null, { reload: 'notifier' });
                }, function() {
                    $state.go('notifier');
                });
            }]
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('InternoDetailController', InternoDetailController);

    InternoDetailController.$inject = ['$scope', '$rootScope','$uibModal','$window', '$stateParams', 'previousState', 'entity', 'Interno','ClienteFacturaPdf', 'Factura', 'Cliente','FileSaver','HogaresPdf'];

    function InternoDetailController($scope, $rootScope,$uibModal,$window, $stateParams, previousState, entity, Interno,ClienteFacturaPdf, Factura, Cliente,FileSaver,HogaresPdf) {
        var vm = this;

        vm.interno = entity;
        vm.previousState = previousState.name;
        vm.descargarPdf=descargarPdf;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:internoUpdate', function(event, result) {
            vm.interno = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        Factura.query({
           // page: pagingParams.page - 1,
            "internoId.equals":  vm.interno.id,
            size: vm.itemsPerPage,
            sort: "fechaEmision,desc",
        }, onSuccess, onError);
    
        function onSuccess(data, headers) {
            // vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.facturas = data;
            // vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        
		function descargarPdf(facturaDto) {
			vm.isSending = true;		
			
			if (facturaDto.modeloDos){
				HogaresPdf.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {				
                    
	                 FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision+'-factura.pdf');
				        
		        }).catch(function (err) {
					vm.isSending = false;
		        });		
			}
			else{
				ClienteFacturaPdf.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {				
                    
	                 FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision+'-factura.pdf');
				        
		        }).catch(function (err) {
					vm.isSending = false;
		        });	
			}
				
		}   
        
        vm.openPopupPagar = function(idFactura) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/entities/interno/pasar-a-paga-dialog.html',
                controller: 'PasarPagaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    idFactura: function() {
                        return idFactura;
                    }
                }
            });

            // Lógica después de cerrar el pop-up
            modalInstance.result.then(function() {
                // Acciones después de cerrar el pop-up, si es necesario
            }, function() {
                // Acciones si se cancela el pop-up, si es necesario
            });
        };

          
        vm.openPopupImpaga = function(idFactura) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/entities/interno/pasar-a-impaga-dialog.html',
                controller: 'PasarPagaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    idFactura: function() {
                        return idFactura;
                    }
                }
            });

            // Lógica después de cerrar el pop-up
            modalInstance.result.then(function() {
                // Acciones después de cerrar el pop-up, si es necesario
            }, function() {
                // Acciones si se cancela el pop-up, si es necesario
            });
        };
    
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('DetalleInformacionDialogController', DetalleInformacionDialogController);

    DetalleInformacionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DetalleInformacion', 'Factura', 'FacturaGrandesClientes'];

    function DetalleInformacionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DetalleInformacion, Factura, FacturaGrandesClientes) {
        var vm = this;

        vm.detalleInformacion = entity;
        vm.clear = clear;
        vm.save = save;
        vm.facturas = Factura.query();
        vm.facturagrandesclientes = FacturaGrandesClientes.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.detalleInformacion.id !== null) {
                DetalleInformacion.update(vm.detalleInformacion, onSaveSuccess, onSaveError);
            } else {
                DetalleInformacion.save(vm.detalleInformacion, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('coopFrontEndApp:detalleInformacionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

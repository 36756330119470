(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller("FacturacionController", FacturacionController);

    FacturacionController.$inject = ["$scope", "FacturacionEstadoService"];

    function FacturacionController($scope, FacturacionEstadoService) {
        var vm = this;
        vm.consultarEstadoAPI = consultarEstadoAPI;
        $scope.statusOk = null;

        function consultarEstadoAPI() {
            console.log("consulto el estado de la API");
            FacturacionEstadoService.check(
                {},
                onCheckStatusOk,
                onCheckStatusError
            );
        }

        function onCheckStatusOk() {
            console.log("API esta OK");
            $scope.statusOk = true;
        }

        function onCheckStatusError() {
            console.log("API esta mal");
            $scope.statusOk = false;
        }

        $scope.getClass = function () {
            switch ($scope.statusOk) {
                case null:
                    return "glyphicon glyphicon-refresh";
                case true:
                    return "glyphicon glyphicon-ok";
                case false:
                    return "glyphicon glyphicon-remove";
            }
        };
    }
})();

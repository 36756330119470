(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('cliente-factura', {
            parent: 'entity',
            url: '/cliente-factura/{id}?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'coopFrontEndApp.factura.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/cliente-factura/cliente-facturas.html',
                    controller: 'ClienteFacturaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('factura');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('macro-pagar', {
            parent: 'cliente-factura',
            url: '/pagar',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cliente-factura/macro-pagar.html',                    
                    controllerAs: 'vm',
                    controller: 'MacroController',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('factura');
                            $translatePartialLoader.addPart('pagos');
                            return $translate.refresh();
                        }],
                        entity: ['ClienteFacturaPagar', function(ClienteFacturaPagar) {
                            return ClienteFacturaPagar.post({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        .state('macro-pagar-gc', {
            parent: 'grandes-cliente-factura',
            url: '/pagar',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cliente-factura/macro-pagar-gc.html',                    
                    controllerAs: 'vm',
                    controller: 'MacroController',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('factura');
                            $translatePartialLoader.addPart('pagos');
                            return $translate.refresh();
                        }],
                        entity: ['ClienteFacturaGcPagar', function(ClienteFacturaGcPagar) {
                            return ClienteFacturaGcPagar.post({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        .state('grandes-cliente-factura', {
            parent: 'entity',
            url: '/grandes-cliente-factura/{id}?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'coopFrontEndApp.factura.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/cliente-factura/grandes-cliente-facturas.html',
                    controller: 'GrandesClienteFacturaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('factura');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }

})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .factory('EventoSearch', EventoSearch);

    EventoSearch.$inject = ['$resource'];

    function EventoSearch($resource) {
        var resourceUrl =  'importerv3/' + 'api/_search/eventos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

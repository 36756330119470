(function () {
    "use strict";
    angular
        .module("coopFrontEndApp")
        .factory("FacturacionPeriodoService", FacturacionPeriodoService)
        .factory(
            "FacturacionPeriodoCloseService",
            FacturacionPeriodoCloseService
        );

    FacturacionPeriodoService.$inject = ["$resource", "DateUtils"];

    FacturacionPeriodoCloseService.$inject = ["$resource", "DateUtils"];

    function FacturacionPeriodoService($resource, DateUtils) {
        var resourceUrl = "importerv3/" + "api/administrador/periodo";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.fechaApertura =
                                DateUtils.convertLocalDateFromServer(
                                    data.fechaApertura
                                );
                            data.fechaCierre =
                                DateUtils.convertLocalDateFromServer(
                                    data.fechaCierre
                                );
                        }
                        return data;
                    },
                },
                open: {
                    method: "POST",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        copy.fechaApertura = DateUtils.convertLocalDateToServer(
                            copy.fechaApertura
                        );
                        copy.fechaCierre = DateUtils.convertLocalDateToServer(
                            copy.fechaCierre
                        );
                        return angular.toJson(copy);
                    },
                },
            }
        );
    }

    function FacturacionPeriodoCloseService($resource, DateUtils) {
        var resourceUrl =
            "importerv3/" + "api/administrador/periodo/:id/cerrar";

        return $resource(
            resourceUrl,
            { id: "@id" },
            {
                close: {
                    method: "PUT",
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        copy.fechaApertura = DateUtils.convertLocalDateToServer(
                            copy.fechaApertura
                        );
                        copy.fechaCierre = DateUtils.convertLocalDateToServer(
                            copy.fechaCierre
                        );
                        return angular.toJson(copy);
                    },
                },
            }
        );
    }
})();

(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Auth', 'Principal', 'AlertService', 'LoginService', 'ClienteInternos', 'ClienteInternosGrandesClientes', 'ClienteFacturas', '$state'];

    function HomeController($scope, Auth, Principal, AlertService, LoginService, ClienteInternos, ClienteInternosGrandesClientes, ClienteFacturas, $state) {
        var vm = this;


        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerNoTitular = registerNoTitular;
        vm.verificarCuenta = verificarCuenta;
        vm.verFacturas = verFacturas;
        vm.logout = logout;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
            getInternos();
            getInternosGrandesClientes();
        });

        getAccount();
        getInternosGrandesClientes();
        getInternos();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }


        function getInternos() {

            ClienteInternos.get().$promise.then(
                //success
                function (data) {/*Do something with value*/
                    vm.internos = data;

                },
                //error
                function (error) {/*Do something with error*/ }
            );
        }

        function logout() {
            Auth.logout();
            $state.go('home');
        }

        function getInternosGrandesClientes() {

            ClienteInternosGrandesClientes.get().$promise.then(
                //success
                function (data) {/*Do something with value*/
                    vm.internosGrandes = data;
                },
                //error
                function (error) {/*Do something with error*/ }
            );
        }

        function verFacturas(id) {
            ClienteFacturas.get(id, function (facturas) {
                vm.facturas = facturas;
            });
        }

        function verificarCuenta() {
            $state.go('verificar');
        }

        function register() {
            $state.go('register');
        }

        function registerNoTitular() {
            $state.go('registerNoTitular');
        }
    }
})();

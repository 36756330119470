(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturasListController', FacturasListController);

    FacturasListController.$inject = ['$state', '$scope', '$stateParams', '$window', 'ClienteFacturaPdf', 'ClienteFactura', 'FacturaSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'FileSaver','ClienteFacturaHistorico','Interno','Cliente'];

    function FacturasListController($state, $scope, $stateParams, $window, ClienteFacturaPdf, ClienteFactura, FacturaSearch, ParseLinks, AlertService, paginationConstants, FileSaver,ClienteFacturaHistorico,Interno,Cliente) {

        var vm = this;
        vm.interno = null;
        vm.numbers = [];
        vm.search = search;
        vm.clear = clear;

   

        

        function descargarPdf(facturaDto) {
            vm.isSending = true;
            ClienteFacturaPdf.descargar({ "facturaId": facturaDto.id }).$promise.then(function (res) {
                FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision + '-factura.pdf');
                vm.isSending = true;
            }).catch(function (err) {
                vm.isSending = false;
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturaGrandesClientesDeleteController',FacturaGrandesClientesDeleteController);

    FacturaGrandesClientesDeleteController.$inject = ['$uibModalInstance', 'entity', 'FacturaGrandesClientes'];

    function FacturaGrandesClientesDeleteController($uibModalInstance, entity, FacturaGrandesClientes) {
        var vm = this;

        vm.facturaGrandesClientes = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            FacturaGrandesClientes.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

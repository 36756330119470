(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('DetalleFacturaGrandesClientesDetailController', DetalleFacturaGrandesClientesDetailController);

    DetalleFacturaGrandesClientesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DetalleFacturaGrandesClientes', 'FacturaGrandesClientesAgua', 'FacturaGrandesClientesEnergia'];

    function DetalleFacturaGrandesClientesDetailController($scope, $rootScope, $stateParams, previousState, entity, DetalleFacturaGrandesClientes, FacturaGrandesClientesAgua, FacturaGrandesClientesEnergia) {
        var vm = this;

        vm.detalleFacturaGrandesClientes = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:detalleFacturaGrandesClientesUpdate', function(event, result) {
            vm.detalleFacturaGrandesClientes = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

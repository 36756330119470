(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('ErrorDetailController', ErrorDetailController);

    ErrorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Error', 'Registro'];

    function ErrorDetailController($scope, $rootScope, $stateParams, previousState, entity, Error, Registro) {
        var vm = this;

        vm.error = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:errorUpdate', function(event, result) {
            vm.error = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
	'use strict';

	angular.module('coopFrontEndApp').controller('PasarPagaDialogController', PasarPagaDialogController);

	PasarPagaDialogController.$inject = ['$scope', '$stateParams', '$window','$uibModalInstance','Factura','AtencionService','idFactura'];

	function PasarPagaDialogController($scope, $stateParams, $window,	$uibModalInstance,Factura,AtencionService,idFactura) {
		var vm = this;

		vm.clear = clear;
        vm.pasarAPaga = pasarAPaga;
        vm.pasarAImpaga = pasarAImpaga;


        vm.idFactura = idFactura;

		
		//loadAll();
		
		function loadAll() {
			vm.isSending = true;
			vm.celulares = Notifier.get_celulares(function(){ vm.isSending = false; });
			
		}
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
		
		

        // Definir función para enviar la factura a pagar
        function pasarAPaga() {
            var idFactura = vm.idFactura; // Reemplaza 'id_factura' con el ID real de la factura
            AtencionService.pagarFactura({ id: idFactura }, function(response) {
                // Lógica para manejar la respuesta después de pagar la factura
                // Por ejemplo, cerrar el pop-up
                vm.clear();
                $window.location.reload(); // Recargar la página

            });
        }

		function pasarAImpaga() {
            var idFactura = vm.idFactura; // Reemplaza 'id_factura' con el ID real de la factura
            AtencionService.anularFactura({ id: idFactura }, function(response) {
                // Lógica para manejar la respuesta después de pagar la factura
                // Por ejemplo, cerrar el pop-up
                vm.clear();
                $window.location.reload(); // Recargar la página

            });
        }
	}
})();








(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller(
            "FacturacionPeriodoCloseController",
            FacturacionPeriodoCloseController
        );

    FacturacionPeriodoCloseController.$inject = [
        "$uibModalInstance",
        "entity",
        "FacturacionPeriodoCloseService",
    ];

    function FacturacionPeriodoCloseController(
        $uibModalInstance,
        entity,
        FacturacionPeriodoCloseService
    ) {
        var vm = this;

        vm.periodo = entity;
        vm.clear = clear;
        vm.confirmClose = confirmClose;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmClose(id) {
            FacturacionPeriodoCloseService.close({ id: id }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();

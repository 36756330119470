(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .factory('MiembroGrupoFamiliarSearch', MiembroGrupoFamiliarSearch);

    MiembroGrupoFamiliarSearch.$inject = ['$resource'];

    function MiembroGrupoFamiliarSearch($resource) {
        var resourceUrl =  'importerv3/' + 'api/_search/miembro-grupo-familiars/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('SuministroController', SuministroController);

    SuministroController.$inject = ['$scope', 'Principal','AlertService','AsociarSuministro','previousState'];

    function SuministroController ($scope, Principal,AlertService,AsociarSuministro,previousState) {
        var vm = this;
        vm.success = null;
        vm.numeroInterno;
        vm.codigoPago;
        vm.asociar = asociar;
        vm.previousState = previousState.name;

        function asociar () {     

        	AsociarSuministro.asociar({"numeroInterno":vm.numeroInterno,"codigoPago":vm.codigoPago}).$promise.then(function () {
                        vm.success = 'OK';
                    }).catch(function (response) {
                        vm.success = null;
                        if (response.status === 400 && response.data.responseData === 'el interno no existe') {
                            vm.errorInternoExists = 'ERROR';
                        }
                        else {
                            vm.error = 'ERROR';
                        }
                    });
                }
    }
})();

(function() {
	'use strict';

	angular.module('coopFrontEndApp').controller('NotifierDialogController', NotifierDialogController);

	NotifierDialogController.$inject = ['$scope', '$stateParams','$uibModalInstance','NotifierFacturaMail','NotifierMailHogares', 'Notifier', 'NotifierSMS' ,'NotifierMails','NotifierMailSocio','NotifierMailsGrandesClientes','NotifierPushSocio','NotifierMailsFeErratas' ];

	function NotifierDialogController($scope, $stateParams,	$uibModalInstance,NotifierFacturaMail,NotifierMailHogares, Notifier, NotifierSMS, NotifierMails,NotifierMailSocio,NotifierMailsGrandesClientes,NotifierPushSocio,NotifierMailsFeErratas) {
		var vm = this;

		vm.clear = clear;
		vm.send_sms = send_sms;
		vm.sendMails = sendMails;
		vm.body = "";
		vm.celulares_custom = "";
		vm.send_all = false;
		vm.response = "";
		vm.numeroSocio="";
		vm.email="";
		vm.sendMailsGrandesClientes=sendMailsGrandesClientes;
		vm.sendPushSocio=sendPushSocio;
		vm.loadAll=loadAll;
		vm.sendMailHogares=sendMailHogares
		vm.sendMailSocioFactura=sendMailSocioFactura;
		vm.sendMailsFeErratas=sendMailsFeErratas;
		
		//loadAll();
		
		function loadAll() {
			vm.isSending = true;
			vm.celulares = Notifier.get_celulares(function(){ vm.isSending = false; });
			
		}
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
		


	     function sendMails() {
	    	 	NotifierMails.send_mails().$promise.then(function (res) {
					vm.response = "Se procederá a enviar los mails a sus destinatarios.";
					vm.isSending = false;
					$uibModalInstance.dismiss('cancel');
		        }).catch(function (err) {
		        	vm.response = err;
		        	alert('Ocurrio un error en el proceso');
					vm.isSending = false;
		        });
	    }
	     
	     function sendMailsGrandesClientes() {
	    	 NotifierMailsGrandesClientes.send_mails().$promise.then(function (res) {
					vm.response = "Se procederá a enviar los mails a sus destinatarios.";
					vm.isSending = false;
					$uibModalInstance.dismiss('cancel');
		        }).catch(function (err) {
		        	vm.response = err;
		        	alert('Ocurrio un error en el proceso');
					vm.isSending = false;
		        });
	    }

		function sendMailsFeErratas() {
			NotifierMailsFeErratas.send_mails().$promise.then(function (res) {
				   vm.response = "Se procederá a enviar los mails a sus destinatarios.";
				   vm.isSending = false;
				   $uibModalInstance.dismiss('cancel');
			   }).catch(function (err) {
				   vm.response = err;
				   alert('Ocurrio un error en el proceso');
				   vm.isSending = false;
			   });
	   }



		
	     
		function sendMailSocioFactura() {
            vm.isSending = true;			
            
            NotifierFacturaMail.send_mail_factura({"idFactura": $stateParams.idFactura, "mail": vm.email}).$promise.then(function (res) {
                vm.response = "Se procederá a enviar la factura al socio.";
                alert('Proceso exitoso');
                vm.isSending = false;
                $uibModalInstance.close(true);
            }).catch(function (err) {
                vm.response = err;
                alert('Ocurrio un error en el proceso');
                vm.isSending = false;
                $uibModalInstance.close(true);
            });
            
        }

	


			
			function sendMailHogares() {
				vm.isSending = true;				
				
				NotifierMailHogares.send_mail({"fecha": vm.fechaEmision}).$promise.then(function (res) {
					vm.response = "Se procederá a enviar las factras a los socios.";
					alert('Proceso exitoso');
					vm.isSending = false;
					$uibModalInstance.close(true);
		        }).catch(function (err) {
		        	vm.response = err;
		        	alert('Ocurrio un error en el proceso');
					vm.isSending = false;
					$uibModalInstance.close(true);
		        });
				
			}
			
			function sendPushSocio() {
				vm.isSending = true;		
				
				NotifierPushSocio.send_push({"loginName": vm.loginName, "mensaje": vm.mensaje}).$promise.then(function (res) {
					vm.response = "Se procederá a enviar una notificacion push a un socio.";
					alert('Proceso exitoso');
					vm.isSending = false;
					$uibModalInstance.close(true);
		        }).catch(function (err) {
		        	vm.response = err;
		        	alert('Ocurrio un error en el proceso');
					vm.isSending = false;
					$uibModalInstance.close(true);
		        });
				
			}	
		
		function send_sms(celulares) {
			var celulares_all = "";
			vm.isSending = true;
			
			if (vm.send_all)
				celulares_all = celulares + vm.celulares_custom;
			else
				celulares_all = vm.celulares_custom;
			
			NotifierSMS.send_sms({"nsc": celulares_all, "body": vm.body}).$promise.then(function (res) {
				vm.response = "Se procederá a enviar los mensajes a sus destinatarios.";
				alert('Proceso exitoso');

				vm.isSending = false;
	        }).catch(function (err) {
	        	vm.response = err;
	        	alert('Ocurrio un error en el proceso');
				vm.isSending = false;
	        });
			
		}
	}
})();








(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('autogestion', {         
            url: '/autogestion',
            data: {
                authorities: ['ROLE_AUTOGESTION'],
                pageTitle: 'coopFrontEndApp.factura.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/autogestion/autogestion.html'
                }
            }
        })
        .state('facturas-list', {
            parent: 'autogestion',
            url: '/facturas-list/{numero}',
            data: {
                authorities: ['ROLE_AUTOGESTION'],
                pageTitle: 'coopFrontEndApp.cliente.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/autogestion/lista-facturas.html',
                    controller: 'AutogestionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('factura');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'autogestion',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
    }

})();

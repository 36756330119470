(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .factory('RechazosSearch', RechazosSearch);

    RechazosSearch.$inject = ['$resource'];

    function RechazosSearch($resource) {
        var resourceUrl =  'coopmercadopago/' + 'api/_search/rechazos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('ClienteDetailController', ClienteDetailController);

    ClienteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Cliente', 'Interno','InternoGrandesClientes'];

    function ClienteDetailController($scope, $rootScope, $stateParams, previousState, entity, Cliente, Interno,InternoGrandesClientes) {
        var vm = this;

        vm.cliente = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:clienteUpdate', function(event, result) {
            vm.cliente = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        Interno.query({
            // page: pagingParams.page - 1,
             "clienteId.equals":  vm.cliente.id,
             size: vm.itemsPerPage,
             sort: "id,desc",
         }, onSuccess, onError);
     
         function onSuccess(data, headers) {
             // vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.internos = data;
             // vm.page = pagingParams.page;
         }
         function onError(error) {
             AlertService.error(error.data.message);
         }
         
         InternoGrandesClientes.query({
             // page: pagingParams.page - 1,
              "clienteId.equals":  vm.cliente.id,
              size: vm.itemsPerPage,
              sort: "id,desc",
          }, onSuccessGrnades, onErrorGrandes);
      
          function onSuccessGrnades(data, headers) {
              // vm.links = ParseLinks.parse(headers('link'));
              vm.totalItems = headers('X-Total-Count');
              vm.queryCount = vm.totalItems;
              vm.internoGrandesClientes = data;
              // vm.page = pagingParams.page;
          }
          function onErrorGrandes(error) {
              AlertService.error(error.data.message);
          }
        
    }
})();

(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('AtencionService', AtencionService);

    AtencionService.$inject = ['$resource', 'DateUtils'];

    function AtencionService ($resource, DateUtils) {
        var resourceUrl = 'importerv3/api/cooperativa/factura';
        
        // Definir los métodos para pagar y anular
        var pagarResource = $resource(resourceUrl + '/pagar/:id', {}, {
            'get': { method: 'GET', isArray: false },
            'pagarFactura': { method: 'GET', url: resourceUrl + '/pagar/:id', isArray: false }
        });

        var anularResource = $resource(resourceUrl + '/anular/:id', {}, {
            'get': { method: 'GET', isArray: false },
            'anularFactura': { method: 'GET', url: resourceUrl + '/anular/:id', isArray: false }
        });

        return {
            pagarFactura: pagarResource.pagarFactura,
            anularFactura: anularResource.anularFactura
        };
    }
})();

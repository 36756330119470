(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller(
            "FacturacionPeriodoDialogController",
            FacturacionPeriodoDialogController
        );

    FacturacionPeriodoDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "entity",
        "Periodo",
    ];

    function FacturacionPeriodoDialogController(
        $timeout,
        $scope,
        $stateParams,
        $uibModalInstance,
        entity,
        Periodo
    ) {
        var vm = this;

        vm.periodo = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.save = save;

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function save() {
            vm.isSaving = true;
            if (vm.periodo.id !== null) {
                Periodo.update(vm.periodo, onSaveSuccess, onSaveError);
            } else {
                Periodo.save(vm.periodo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("coopFrontEndApp:periodoUpdate", result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

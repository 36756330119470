(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturaController', FacturaController);

    FacturaController.$inject = ['$state', 'Factura', 'FacturaSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','FileSaver','HogaresPdf'];

    function FacturaController($state, Factura, FacturaSearch, ParseLinks, AlertService, paginationConstants, pagingParams,FileSaver,HogaresPdf) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.descargarPdf= descargarPdf;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                FacturaSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Factura.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.facturas = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        
        function descargarPdf(facturaDto) {
			vm.isSending = true;		
			
			if (facturaDto.modeloDos){
				HogaresPdf.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {				
                    
	                 FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision+'-factura.pdf');
				        
		        }).catch(function (err) {
					vm.isSending = false;
		        });		
			}
			else{
				ClienteFacturaPdf.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {				
                    
	                 FileSaver.saveAs(res.response,  facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision+'-factura.pdf');
				        
		        }).catch(function (err) {
					vm.isSending = false;
		        });	
			}
				
		} 
        
    }
})();

(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('ClienteFacturaGrandesClientesAgua', ClienteFacturaGrandesClientesAgua);

    ClienteFacturaGrandesClientesAgua.$inject = ['$resource', 'DateUtils'];

    function ClienteFacturaGrandesClientesAgua ($resource, DateUtils) {
        var resourceUrl =  'importerv3/' + 'api/cliente/factura-grandes-clientes-aguas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fechaLecturaActualUno = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualUno);
                        data.fechaLecturaAnteriorUno = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorUno);
                        data.fechaLecturaActualDos = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualDos);
                        data.fechaLecturaAnteriorDos = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorDos);
                        data.fechaLecturaActualTres = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualTres);
                        data.fechaLecturaAnteriorTres = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorTres);
                        data.fechaLecturaActualCuatro = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualCuatro);
                        data.fechaLecturaAnteriorCuatro = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorCuatro);
                        data.fechaLecturaActualCinco = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualCinco);
                        data.fechaLecturaAnteriorCinco = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorCinco);
                        data.fechaVencimiento = DateUtils.convertLocalDateFromServer(data.fechaVencimiento);
                        data.fechaProximoVencimiento = DateUtils.convertLocalDateFromServer(data.fechaProximoVencimiento);
                        data.fechaEmision = DateUtils.convertLocalDateFromServer(data.fechaEmision);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaLecturaActualUno = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualUno);
                    copy.fechaLecturaAnteriorUno = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorUno);
                    copy.fechaLecturaActualDos = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualDos);
                    copy.fechaLecturaAnteriorDos = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorDos);
                    copy.fechaLecturaActualTres = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualTres);
                    copy.fechaLecturaAnteriorTres = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorTres);
                    copy.fechaLecturaActualCuatro = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualCuatro);
                    copy.fechaLecturaAnteriorCuatro = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorCuatro);
                    copy.fechaLecturaActualCinco = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualCinco);
                    copy.fechaLecturaAnteriorCinco = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorCinco);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximoVencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximoVencimiento);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaLecturaActualUno = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualUno);
                    copy.fechaLecturaAnteriorUno = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorUno);
                    copy.fechaLecturaActualDos = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualDos);
                    copy.fechaLecturaAnteriorDos = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorDos);
                    copy.fechaLecturaActualTres = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualTres);
                    copy.fechaLecturaAnteriorTres = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorTres);
                    copy.fechaLecturaActualCuatro = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualCuatro);
                    copy.fechaLecturaAnteriorCuatro = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorCuatro);
                    copy.fechaLecturaActualCinco = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualCinco);
                    copy.fechaLecturaAnteriorCinco = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorCinco);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximoVencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximoVencimiento);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();

(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller(
            "ArchivoFacturacionUploadDialogController",
            ArchivoFacturacionUploadDialogController
        )
        .directive("fileModel", [
            "$parse",
            function ($parse) {
                return {
                    restrict: "A",
                    link: function (scope, element, attrs) {
                        var model = $parse(attrs.fileModel);
                        var modelSetter = model.assign;
                        element.bind("change", function () {
                            scope.$apply(function () {
                                modelSetter(scope, element[0].files[0]);
                            });
                        });
                    },
                };
            },
        ]);

    ArchivoFacturacionUploadDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "FacturacionHogaresService",
        "FacturacionGrandesClientesService",
        "hogares",
        "grandesClientes",
    ];

    function ArchivoFacturacionUploadDialogController(
        $timeout,
        $scope,
        $stateParams,
        $uibModalInstance,
        facturacionHogaresService,
        facturacionGrandesClientesService,
        hogares,
        grandesClientes
    ) {
        var vm = this;
        vm.clear = clear;
        vm.uploadFile = uploadFile;
        vm.idPeriodo = $stateParams.id;
        vm.hogares = hogares;
        vm.grandesClientes = grandesClientes;

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function uploadFile() {
            vm.isSaving = true;

            var fd = new FormData();
            fd.append("file", document.getElementById("file").files[0]);

            if (vm.hogares) {
                facturacionHogaresService
                    .upload({ id: vm.idPeriodo }, fd)
                    .$promise.then(function (res) {
                        alert(
                            "El Archivo de facturación de Hogares y se ha comenzado con su procesamiento!"
                        );
                        $uibModalInstance.close(true);
                    })
                    .catch(function (err) {
                        alert(
                            "Ocurrió un error al subir el Arichvo de facturación de Hogares!"
                        );
                        throw err;
                    });
            }

            if (vm.grandesClientes) {
                facturacionGrandesClientesService
                    .upload({ id: vm.idPeriodo }, fd)
                    .$promise.then(function (res) {
                        alert(
                            "El Archivo de facturación de Grandes Clientes y se ha comenzado con su procesamiento!"
                        );
                        $uibModalInstance.close(true);
                    })
                    .catch(function (err) {
                        alert(
                            "Ocurrió un error al subir el Arichvo de facturación de Grandes Clientes!"
                        );
                        throw err;
                    });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('SuministroDeleteController', SuministroDeleteController);

    SuministroDeleteController.$inject = ['$uibModalInstance', 'entity', 'DesvincularSuministro'];

    function SuministroDeleteController($uibModalInstance, entity, DesvincularSuministro) {
        var vm = this;

        vm.interno = entity;
        vm.clear = clear;
        vm.confirmDesvincular = confirmDesvincular;


        
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDesvincular(id) {
            DesvincularSuministro.desvincular({ "numeroInterno": id }).$promise.then(function () {
                vm.success = 'OK';
                $uibModalInstance.close(true);
            
            }).catch(function (response) {
                vm.success = null;
                $uibModalInstance.close(true);
                if (response.status === 400 && response.data.responseData === 'el interno no existe') {
                    vm.errorInternoExists = 'ERROR';
                }
                else {
                    vm.error = 'ERROR';
                }
            });
        }
    }
})();

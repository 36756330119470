(function () {
    "use strict";

    angular.module("coopFrontEndApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("facturacion-periodo", {
                parent: "periodo",
                url: "/periodo?page&sort&search",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                    pageTitle: "coopFrontEndApp.periodo.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/facturacion/periodo/periodos.html",
                        controller: "FacturacionPeriodoController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("facturacion");
                            $translatePartialLoader.addPart("periodo");
                            $translatePartialLoader.addPart("lote");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("facturacion-periodo-detail", {
                parent: "facturacion-periodo",
                url: "/periodo/{id}",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                    pageTitle: "coopFrontEndApp.periodo.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/facturacion/periodo/periodo-detail.html",
                        controller: "FacturacionPeriodoDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("periodo");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "Periodo",
                        function ($stateParams, Periodo) {
                            return Periodo.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name:
                                    $state.current.name ||
                                    "facturacion-periodo",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("facturacion-periodo-detail.edit", {
                parent: "facturacion-periodo-detail",
                url: "/detail/edit",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/periodo/periodo-dialog.html",
                                controller:
                                    "FacturacionPeriodoDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: [
                                        "Periodo",
                                        function (Periodo) {
                                            return Periodo.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("^", {}, { reload: false });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("facturacion-periodo.open", {
                parent: "periodo",
                url: "/open",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/periodo/periodo-open-dialog.html",
                                controller:
                                    "FacturacionPeriodoOpenDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: function () {
                                        return {
                                            nombre: null,
                                            fechaApertura: null,
                                            usuarioApertura: null,
                                            fechaCierre: null,
                                            usuarioCierre: null,
                                            finalizado: null,
                                            id: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("facturacion-periodo", null, {
                                        reload: "facturacion-periodo",
                                    });
                                },
                                function () {
                                    $state.go("facturacion-periodo");
                                }
                            );
                    },
                ],
            })
            .state("facturacion-periodo.close", {
                parent: "facturacion-periodo-detail",
                url: "/{id}/close",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/periodo/periodo-close-dialog.html",
                                controller: "FacturacionPeriodoCloseController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Periodo",
                                        function (Periodo) {
                                            return Periodo.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("facturacion-periodo", null, {
                                        reload: "facturacion-periodo",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("facturacion-periodo.edit", {
                parent: "facturacion-periodo",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/periodo/periodo-dialog.html",
                                controller:
                                    "FacturacionPeriodoDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: [
                                        "Periodo",
                                        function (Periodo) {
                                            return Periodo.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("facturacion-periodo", null, {
                                        reload: "facturacion-periodo",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("facturacion-periodo.delete", {
                parent: "facturacion-periodo",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/periodo/periodo-delete-dialog.html",
                                controller:
                                    "FacturacionPeriodoDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Periodo",
                                        function (Periodo) {
                                            return Periodo.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("facturacion-periodo", null, {
                                        reload: "facturacion-periodo",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("upload-hogares", {
                parent: "facturacion-periodo-detail",
                url: "facturacion-lote/upload/hogares",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$state",
                    "$uibModal",
                    function ($state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/periodo/archivo-facturacion-upload/archivo-facturacion-upload-dialog.html",
                                controller:
                                    "ArchivoFacturacionUploadDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    hogares: true,
                                    grandesClientes: false,
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go(
                                        "facturacion-periodo-detail",
                                        null,
                                        {
                                            reload: "facturacion-periodo-detail",
                                        }
                                    );
                                },
                                function () {
                                    $state.go("facturacion-periodo-detail");
                                }
                            );
                    },
                ],
            })
            .state("upload-grandes-clientes", {
                parent: "facturacion-periodo-detail",
                url: "facturacion-lote/upload/grandes-clientes",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$state",
                    "$uibModal",
                    function ($state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/periodo/archivo-facturacion-upload/archivo-facturacion-upload-dialog.html",
                                controller:
                                    "ArchivoFacturacionUploadDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    hogares: false,
                                    grandesClientes: true,
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go(
                                        "facturacion-periodo-detail",
                                        null,
                                        {
                                            reload: "facturacion-periodo-detail",
                                        }
                                    );
                                },
                                function () {
                                    $state.go("facturacion-periodo-detail");
                                }
                            );
                    },
                ],
            });
    }
})();

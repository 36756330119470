(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('interno');
                    $translatePartialLoader.addPart('factura');
                    return $translate.refresh();
                }]
            }
        })
        .state('ayuda', {
            parent: 'home',
            url: '/ayuda',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/ayuda.html',
                }
            }            
        })
       .state('agregarSuministro', {
            parent: 'app',
            url: '/agregar-suministro',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/agregar-suministro.html',
                    controller: 'SuministroController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	$translatePartialLoader.addPart('register');
                	 $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }          
        })
        .state('administrarSuministros', {
            parent: 'app',
            url: '/administrar-suministros',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/administrar-suministros.html',
                    controller: 'AdministrarSuministrosController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	$translatePartialLoader.addPart('register');
                     $translatePartialLoader.addPart('home');
                     $translatePartialLoader.addPart('interno');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }          
        })
        .state('administrarSuministros.delete', {
            parent: 'administrarSuministros',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/home/suministro-delete-dialog.html',
                    controller: 'SuministroDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Interno', function(Interno) {
                            return Interno.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('administrarSuministros', null, { reload: 'administrarSuministros' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
       .state('agregarSocio', {
            parent: 'app',
            url: '/agregar-socio',
            data: {
                authorities: ['ROLE_COOP','ROLE_ADMIN','ROLE_ATENCION']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/agregar-socio.html',
                    controller: 'AgregarSocioController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	$translatePartialLoader.addPart('register');
                	 $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }          
        })
       .state('agregarSocioSinFactura', {
            parent: 'app',
            url: '/agregar-socio-sin-factura',
            data: {
                authorities: ['ROLE_COOP','ROLE_ADMIN','ROLE_ATENCION']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/agregar-socio-sin-factura.html',
                    controller: 'AgregarSocioSinFacturaController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	$translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('nuevosUsuarios');
                	$translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }          
        })
        .state('formas-de-pago', {
            parent: 'app',
            url: '/formas-de-pago',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/formas-de-pago.html',
                    controller: 'FormasDePagoController',
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	$translatePartialLoader.addPart('register');
                	 $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }          
        })
        .state('agregarNoSocio', {
            parent: 'app',
            url: '/agregar-no-socio',
            data: {
                authorities: ['ROLE_COOP','ROLE_ADMIN','ROLE_ATENCION']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/agregar-no-titular.html',
                    controller: 'AgregarSocioController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	$translatePartialLoader.addPart('register');
                	 $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }          
        })
        .state('cliente.factura-adherir', {
            parent: 'home',
            url: '/{id}/adherir',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cliente-factura/factura-adherir-dialog.html',
                    controller: 'ClienteFacturaAdherirController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Interno', function(Interno) {
                            return Interno.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('home', null, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }
})();

(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller(
            "FacturasPagasDialogController",
            FacturasPagasDialogController
        )
        .directive("fileModel", [
            "$parse",
            function ($parse) {
                return {
                    restrict: "A",
                    link: function (scope, element, attrs) {
                        var model = $parse(attrs.fileModel);
                        var modelSetter = model.assign;
                        element.bind("change", function () {
                            scope.$apply(function () {
                                modelSetter(scope, element[0].files[0]);
                            });
                        });
                    },
                };
            },
        ]);

    FacturasPagasDialogController.$inject = [
        "$timeout",
        "$uibModalInstance",
        "FacturacionFacturasPagasService",
    ];

    function FacturasPagasDialogController(
        $timeout,
        $uibModalInstance,
        facturacionService
    ) {
        var vm = this;
        vm.clear = clear;
        vm.savePaga = savePaga;

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function savePaga() {
            vm.isSaving = true;

            var fd = new FormData();
            fd.append("file", document.getElementById("file").files[0]);

            facturacionService
                .upload(fd)
                .$promise.then(function (res) {
                    alert(
                        "El Archivo de Pagos ha sido subido exitosamente y se ha comenzado con su procesamiento!"
                    );
                    $uibModalInstance.close(true);
                })
                .catch(function (err) {
                    alert("Ocurrió un error al subir el Arichvo de Pagos!");
                    throw err;
                });
        }
    }
})();

(function () {
    "use strict";

    angular.module("coopFrontEndApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("facturacion", {
                parent: "app",
                url: "/facturacion",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                    pageTitle: "coopFrontEndApp.facturacion.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/facturacion/facturacion.html",
                        controller: "FacturacionController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    authorize: [
                        "Auth",
                        function (Auth) {
                            return Auth.authorize();
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("facturacion");
                            $translatePartialLoader.addPart("periodo");
							$translatePartialLoader.addPart('notificador');
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("facturas-pagas", {
                parent: "facturacion",
                url: "/facturas-pagas?page&sort&search",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                    pageTitle: "coopFrontEndApp.registro.home.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/facturacion/facturas-pagas/facturas-pagas.html",
                        controller: "FacturasPagasController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("registro");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("facturas-pagas-upload", {
                parent: "facturas-pagas",
                url: "facturas-pagas/upload",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/facturas-pagas/facturas-pagas-dialog.html",
                                controller: "FacturasPagasDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                            })
                            .result.then(
                                function () {
                                    $state.go("facturas-pagas", null, {
                                        reload: "facturas-pagas",
                                    });
                                },
                                function () {
                                    $state.go("facturas-pagas");
                                }
                            );
                    },
                ],
            })
            .state("facturacion-enviar-correos", {
                parent: "facturacion",
                url: "send-mails",
                data: {
                    authorities: ["ROLE_FACTURACION"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/facturacion/enviar-correo/enviar-correo-dialog.html",
                                controller: "FacturasEnviarCorreoController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                            })
                            .result.then(
                                function () {
                                    $state.go("facturas-pagas", null, {
                                        reload: "facturacion",
                                    });
                                },
                                function () {
                                    $state.go("facturacion");
                                }
                            );
                    },
                ],
            });
    }
})();

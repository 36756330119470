(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService'];

    function RegisterController ($translate, $timeout, Auth, LoginService) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerNoTitular=registerNoTitular;
        vm.registerAccount = {};
        vm.celular = {};
        vm.success = null;
        vm.terminos = {};

        $timeout(function (){angular.element('#login').focus();});

        function register () {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            }
            else{
            	if (vm.registerAccount.email !== vm.confirmEmail) {
                    vm.doNotMatchCorreo = 'ERROR';
                }else {
                	vm.registerAccount.celular=vm.celular.code+""+vm.celular.numero;
                    vm.registerAccount.langKey = $translate.use();
                    vm.doNotMatch = null;
                    vm.error = null;
                    vm.errorUserExists = null;
                    vm.errorEmailExists = null;

                    Auth.createAccount(vm.registerAccount).then(function () {
                        vm.success = 'OK';
                    }).catch(function (response) {
                        vm.success = null;
                        if (response.status === 400 && response.data === 'login already in use') {
                            vm.errorUserExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'email address already in use') {
                            vm.errorEmailExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'El interno y codigo de pago no coincide con el Cliente') {
                            vm.errorValidData = 'ERROR'; 
                        }
                        else {
                            vm.error = 'ERROR';
                        }
                    });
                }
            } 
        }


        function registerNoTitular () {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            }
            else{
            	if (vm.registerAccount.email !== vm.confirmEmail) {
                    vm.doNotMatchCorreo = 'ERROR';
                }else {
                	vm.registerAccount.celular=vm.celular.code+""+vm.celular.numero;
                    vm.registerAccount.langKey = $translate.use();
                    vm.doNotMatch = null;
                    vm.error = null;
                    vm.errorUserExists = null;
                    vm.errorEmailExists = null;

                    Auth.createCuentaNoTitular(vm.registerAccount).then(function () {
                        vm.success = 'OK';
                    }).catch(function (response) {
                        vm.success = null;
                        if (response.status === 400 && response.data === 'login already in use') {
                            vm.errorUserExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'email address already in use') {
                            vm.errorEmailExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'El interno y codigo de pago no coincide con el Cliente') {
                            vm.errorValidData = 'ERROR'; 
                        }
                        else {
                            vm.error = 'ERROR';
                        }
                    });
                }
            } 
        }
    }
})();

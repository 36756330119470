(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturaGrandesClientesDialogController', FacturaGrandesClientesDialogController);

    FacturaGrandesClientesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FacturaGrandesClientes', 'DetalleFacturaMora', 'DetalleFactura', 'InternoGrandesClientes', 'DetalleInformacion', 'Periodo'];

    function FacturaGrandesClientesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FacturaGrandesClientes, DetalleFacturaMora, DetalleFactura, InternoGrandesClientes, DetalleInformacion, Periodo) {
        var vm = this;

        vm.facturaGrandesClientes = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.detallefacturamoras = DetalleFacturaMora.query();
        vm.detallefacturas = DetalleFactura.query();
        vm.internograndesclientes = InternoGrandesClientes.query();
        vm.detalleinformacions = DetalleInformacion.query();
        vm.periodos = Periodo.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.facturaGrandesClientes.id !== null) {
                FacturaGrandesClientes.update(vm.facturaGrandesClientes, onSaveSuccess, onSaveError);
            } else {
                FacturaGrandesClientes.save(vm.facturaGrandesClientes, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('coopFrontEndApp:facturaGrandesClientesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.fechaEmision = false;
        vm.datePickerOpenStatus.fechaLecturaActualEnergiaActiva = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorEnergiaActiva = false;
        vm.datePickerOpenStatus.fechaLecturaActualEnergiaReactiva = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorEnergiaReactiva = false;
        vm.datePickerOpenStatus.fechaVencimiento = false;
        vm.datePickerOpenStatus.fechaProximoVencimiento = false;
        vm.datePickerOpenStatus.fechaLecturaActualUno = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorUno = false;
        vm.datePickerOpenStatus.fechaLecturaActualDos = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorDos = false;
        vm.datePickerOpenStatus.fechaLecturaActualTres = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorTres = false;
        vm.datePickerOpenStatus.fechaLecturaActualCuatro = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorCuatro = false;
        vm.datePickerOpenStatus.fechaLecturaActualCinco = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorCinco = false;
        vm.datePickerOpenStatus.vencimientoCae = false;
        vm.datePickerOpenStatus.fechaPago = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

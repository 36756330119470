(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('Factura', Factura);

    Factura.$inject = ['$resource', 'DateUtils'];

    function Factura ($resource, DateUtils) {
        var resourceUrl =  'importerv3/' + 'api/facturas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fechaEmision = DateUtils.convertLocalDateFromServer(data.fechaEmision);
                        data.fechaLecturaActual = DateUtils.convertLocalDateFromServer(data.fechaLecturaActual);
                        data.fechaLecturaAnterior = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnterior);
                        data.fechaLecturaActualSanitario = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualSanitario);
                        data.fechaLecturaAnteriorSanitario = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorSanitario);
                        data.fechaVencimiento = DateUtils.convertLocalDateFromServer(data.fechaVencimiento);
                        data.fechaProximoVencimiento = DateUtils.convertLocalDateFromServer(data.fechaProximoVencimiento);
                        data.fechaPago = DateUtils.convertLocalDateFromServer(data.fechaPago);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    copy.fechaLecturaActual = DateUtils.convertLocalDateToServer(copy.fechaLecturaActual);
                    copy.fechaLecturaAnterior = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnterior);
                    copy.fechaLecturaActualSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualSanitario);
                    copy.fechaLecturaAnteriorSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorSanitario);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximoVencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximoVencimiento);
                    copy.fechaPago = DateUtils.convertLocalDateToServer(copy.fechaPago);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    copy.fechaLecturaActual = DateUtils.convertLocalDateToServer(copy.fechaLecturaActual);
                    copy.fechaLecturaAnterior = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnterior);
                    copy.fechaLecturaActualSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualSanitario);
                    copy.fechaLecturaAnteriorSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorSanitario);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximoVencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximoVencimiento);
                    copy.fechaPago = DateUtils.convertLocalDateToServer(copy.fechaPago);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();

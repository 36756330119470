(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('PeriodoDialogController', PeriodoDialogController);

    PeriodoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Periodo', 'Lote', 'Factura', 'FacturaGrandesClientes'];

    function PeriodoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Periodo, Lote, Factura, FacturaGrandesClientes) {
        var vm = this;

        vm.periodo = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.lotes = Lote.query();
        vm.facturas = Factura.query();
        vm.facturagrandesclientes = FacturaGrandesClientes.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.periodo.id !== null) {
                Periodo.update(vm.periodo, onSaveSuccess, onSaveError);
            } else {
                Periodo.save(vm.periodo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('coopFrontEndApp:periodoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.fechaApertura = false;
        vm.datePickerOpenStatus.fechaCierre = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

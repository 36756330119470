(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturaGrandesClientesDetailController', FacturaGrandesClientesDetailController);

    FacturaGrandesClientesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FacturaGrandesClientes', 'DetalleFacturaMora', 'DetalleFactura', 'InternoGrandesClientes', 'DetalleInformacion', 'Periodo'];

    function FacturaGrandesClientesDetailController($scope, $rootScope, $stateParams, previousState, entity, FacturaGrandesClientes, DetalleFacturaMora, DetalleFactura, InternoGrandesClientes, DetalleInformacion, Periodo) {
        var vm = this;

        vm.facturaGrandesClientes = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:facturaGrandesClientesUpdate', function(event, result) {
            vm.facturaGrandesClientes = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

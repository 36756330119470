(function() {
	'use strict';

	angular.module('coopFrontEndApp').controller('FacturasEnviarCorreoController', FacturasEnviarCorreoController);

	FacturasEnviarCorreoController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'NotifierMailHogares', 'Notifier', 'NotifierSMS', 'NotifierMails', 'NotifierMailSocio', 'NotifierMailsGrandesClientes', 'NotifierPushSocio'];

	function FacturasEnviarCorreoController($scope, $stateParams, $uibModalInstance, NotifierMailHogares, Notifier, NotifierSMS, NotifierMails, NotifierMailSocio, NotifierMailsGrandesClientes, NotifierPushSocio) {
		var vm = this;

		vm.clear = clear;
		vm.sendMails = sendMails;


		function clear() {
			$uibModalInstance.dismiss('cancel');
		}



		function sendMails() {
			NotifierMails.send_mails().$promise.then(function(res) {
				vm.response = "Se procederá a enviar los mails a los Socios.";
				alert('Comenzo el envio de correos, verifique el estado del evento Enviar_Correos');
				vm.isSending = false;
				$uibModalInstance.dismiss('cancel');
			}).catch(function(err) {
				vm.response = err;
				alert('Ocurrio un error en el proceso');
				vm.isSending = false;
			});
		}


	}
})();








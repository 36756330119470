(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('ClienteInternosGrandesClientes', ClienteInternosGrandesClientes)
        .factory('ClienteInternos', ClienteInternos);

    ClienteInternos.$inject = ['$resource'];
    ClienteInternosGrandesClientes.$inject = [ '$resource' ];

    function ClienteInternos ($resource) {
        var resourceUrl =  'importerv3/' + 'api/cliente/internos/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                isArray: true
            },
            'update': { method:'PUT' }
        });
    }
    
   
	function ClienteInternosGrandesClientes($resource) {
		var resourceUrl =  'importerv3/' + 'api/cliente/internosGrandesClientes/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                isArray: true
            },
            'update': { method:'PUT' }
        });
	}
    
    
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .factory('DetalleFacturaGrandesClientesSearch', DetalleFacturaGrandesClientesSearch);

    DetalleFacturaGrandesClientesSearch.$inject = ['$resource'];

    function DetalleFacturaGrandesClientesSearch($resource) {
        var resourceUrl =  'importerv3/' + 'api/_search/detalle-factura-grandes-clientes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

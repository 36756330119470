(function () {
    "use strict";
    angular
        .module("coopFrontEndApp")
        .factory("Interno", Interno)
        .factory("InternoBeneficio", InternoBeneficio);

    Interno.$inject = ["$resource"];
    InternoBeneficio.$inject = ["$resource"];

    function Interno($resource) {
        var resourceUrl = "importerv3/" + "api/internos/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }

    function InternoBeneficio($resource) {
        var resourceUrl = "importerv3/" + "api/cliente/beneficio";

        return $resource(
            resourceUrl,
            {},
            {
                aplicar: {
                    method: "POST",
                    timeout: 30 * 60 * 1000,
                    // headers: {
                    //     "Content-Type": undefined,
                    //     "Access-Control-Request-Headers":
                    //         "accept, origin, x-requested-with",
                    //     "Access-Control-Request-Method":
                    //         "POST, PUT, GET, DELETE",
                    // },
                    transformResponse: function (data) {
                        return {
                            responseData: data.toString(),
                        };
                    },
                },
            }
        );
    }
})();

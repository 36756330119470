(function () {
    "use strict";

    angular.module("coopFrontEndApp").component("listadoLotes", {
        templateUrl: "app/facturacion/periodo/listado-lotes/listado-lotes.html",
        controller: ("ListadoLotesController", ListadoLotesController),
        controllerAs: "vm",
        bindings: {
            periodo: "<",
        },
    });

    ListadoLotesController.$inject = [
        "Lote",
        "LoteSearch",
        "AlertService",
        "FileSaver",
    ];

    function ListadoLotesController(Lote, LoteSearch, AlertService, FileSaver) {
        var vm = this;
        vm.lotes = [];
        vm.descargar = descargar;

        loadLotes();

        function loadLotes() {
            LoteSearch.query(
                {
                    query: "periodoId.equals=" + vm.periodo.id,
                    page: 0,
                    size: 999,
                    sort: ["timeStamp,DESC"],
                },
                onSuccess,
                onError
            );
        }

        function onSuccess(data, headers) {
            vm.lotes = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function descargar(loteId, tipoArchivo) {
            Lote.get({ id: loteId }).$promise.then(function (data) {
                return onDownloadSuccess(data, tipoArchivo);
            }, onDownloadError);
        }

        function onDownloadSuccess(lote, tipoArchivo) {
            switch (tipoArchivo) {
                case "total":
                    FileSaver.saveAs(
                        base64toBlob(
                            lote.archivoFacturasSubidas,
                            lote.archivoFacturasSubidasContentType
                        ),
                        extractFilename(lote.pathArchivoFacturasSubidas)
                    );
                    AlertService.success(
                        "coopFrontEndApp.facturacion.lotes.archivos.downloadOk"
                    );
                    break;
                case "ok":
                    FileSaver.saveAs(
                        base64toBlob(
                            lote.archivoFacturasHomologadas,
                            lote.archivoFacturasHomologadasContentType
                        ),
                        extractFilename(lote.pathArchivoFacturasHomologadas)
                    );
                    AlertService.success(
                        "coopFrontEndApp.facturacion.lotes.archivos.downloadOk"
                    );
                    break;
                case "error":
                    FileSaver.saveAs(
                        base64toBlob(
                            lote.archivoFacturasErroneas,
                            lote.archivoFacturasErroneasContentType
                        ),
                        extractFilename(lote.pathArchivoFacturasErroneas)
                    );
                    AlertService.success(
                        "coopFrontEndApp.facturacion.lotes.archivos.downloadOk"
                    );
                    break;
                default:
                    console.error(
                        "No se reconoce el tipo de tipoArchivo " + tipoArchivo
                    );
                    AlertService.error(
                        "error.facturacionErrorlFileNotRecognized",
                        { param: tipoArchivo }
                    );
            }
        }

        function onDownloadError() {
            AlertService.error("error.facturacionTotalDownloadError");
        }

        function base64toBlob(base64Data, contentType) {
            contentType = contentType || "";
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);

                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: contentType });
        }

        function extractFilename(path) {
            if (path.substr(0, 12) == "C:\\fakepath\\") return path.substr(12); // modern browser
            var x;
            x = path.lastIndexOf("/");
            if (x >= 0)
                // Unix-based path
                return path.substr(x + 1);
            x = path.lastIndexOf("\\");
            if (x >= 0)
                // Windows-based path
                return path.substr(x + 1);
            return path; // just the file name
        }
    }
})();

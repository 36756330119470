(function () {
    "use strict";

    angular.module("coopFrontEndApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("interno", {
                parent: "entity",
                url: "/interno?page&sort&search",
                data: {
                    authorities: ["ROLE_ADMIN","ROLE_FACTURACION"],
                    pageTitle: "coopFrontEndApp.interno.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/interno/internos.html",
                        controller: "InternoController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("interno");
                            $translatePartialLoader.addPart("global");
                            $translatePartialLoader.addPart("factura");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("interno-detail", {
                parent: "interno",
                url: "/interno/{id}",
                data: {
                    authorities: ["ROLE_ADMIN","ROLE_ATENCION"],
                    pageTitle: "coopFrontEndApp.interno.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/interno/interno-detail.html",
                        controller: "InternoDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("interno");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "Interno",
                        function ($stateParams, Interno) {
                            return Interno.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "interno",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("interno-detail.edit", {
                parent: "interno-detail",
                url: "/detail/edit",
                data: {
                    authorities: ["ROLE_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/interno/interno-dialog.html",
                                controller: "InternoDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: [
                                        "Interno",
                                        function (Interno) {
                                            return Interno.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("^", {}, { reload: false });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("interno.new", {
                parent: "interno",
                url: "/new",
                data: {
                    authorities: ["ROLE_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/interno/interno-dialog.html",
                                controller: "InternoDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: function () {
                                        return {
                                            numeroInterno: null,
                                            domicilioSuministro: null,
                                            domicilioPostal: null,
                                            adheridoFactura: null,
                                            activo: null,
                                            id: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("interno", null, {
                                        reload: "interno",
                                    });
                                },
                                function () {
                                    $state.go("interno");
                                }
                            );
                    },
                ],
            })
            .state("interno.edit", {
                parent: "interno",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/interno/interno-dialog.html",
                                controller: "InternoDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: [
                                        "Interno",
                                        function (Interno) {
                                            return Interno.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("interno", null, {
                                        reload: "interno",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("interno.delete", {
                parent: "interno",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:"app/entities/interno/interno-delete-dialog.html",
                                controller: "InternoDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Interno",
                                        function (Interno) {
                                            return Interno.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("interno", null, {
                                        reload: "interno",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })            
          .state('factura.send-email-socio', {
                parent: 'interno-detail',
                url: '/{idFactura}/enviar-mail',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_ATENCION']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/interno/send-factura-to-socio-dialog.html',
                        controller: 'NotifierDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md'
                    }).result.then(function() {
                        $state.go('interno-detail', null, { reload: 'notifier' });
                    }, function() {
                        $state.go('interno-detail');
                    });
                }],
                resolve: {
                    translatePartialLoader: ["$translate","$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("interno");
                            return $translate.refresh();
                        },
                    ]}
        })

            
        .state("interno-beneficio", {
                parent: "interno",
                url: "/interno/beneficio/{tipo}/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "coopFrontEndApp.interno.beneficio.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/interno/interno-beneficio.html",
                        controller: "InternoBeneficioController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("interno");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "Interno",
                        "InternoGrandesClientes",
                        function (
                            $stateParams,
                            Interno,
                            InternoGrandesClientes
                        ) {
                            if ($stateParams.tipo === "grandes-clientes") {
                                return InternoGrandesClientes.get({
                                    id: $stateParams.id,
                                }).$promise;
                            } else {
                                return Interno.get({
                                    id: $stateParams.id,
                                }).$promise;
                            }
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: "home",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })

            .state('factura-pasar-paga', {
                parent: 'interno-detail',
                url: '/atencion-pasar-a-paga/{id}/pasar-paga',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_ATENCION']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/interno/pasar-a-paga-dialog.html',
                        controller: 'PasarPagaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md'
                    }).result.then(function() {
                        $state.go('interno-detail', null, { reload: 'notifier' });
                    }, function() {
                        $state.go('interno-detail');
                    });
                }],
                resolve: {
                    translatePartialLoader: ["$translate","$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("interno");
                            return $translate.refresh();
                        },
                    ]}
        })
    }
})();

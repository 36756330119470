(function () {
    "use strict";
    angular
        .module("coopFrontEndApp")
        .factory(
            "FacturacionFacturasPagasService",
            FacturacionFacturasPagasService
        )
        .factory("FacturacionHogaresService", FacturacionHogaresService)
        .factory(
            "FacturacionGrandesClientesService",
            FacturacionGrandesClientesService
        )
        .factory("FacturacionEstadoService", FacturacionEstadoService)
        .config([
            "$httpProvider",
            function ($httpProvider) {
                $httpProvider.defaults.timeout = 200000;
            },
        ]);

    FacturacionFacturasPagasService.$inject = ["$resource"];
    FacturacionHogaresService.$inject = ["$resource"];
    FacturacionGrandesClientesService.$inject = ["$resource"];
    FacturacionEstadoService.$inject = ["$resource"];

    var resourceUrlAdministrador = "importerv3/" + "api/administrador";

    function FacturacionFacturasPagasService($resource) {
        return $resource(
            resourceUrlAdministrador + "/importar-pagos",
            {},
            {
                upload: {
                    method: "POST",
                    timeout: 30 * 60 * 1000,
                    headers: {
                        "Content-Type": undefined,
                        "Access-Control-Request-Headers":
                            "accept, origin, x-requested-with",
                        "Access-Control-Request-Method":
                            "POST, PUT, GET, DELETE",
                    },
                    transformRequest: angular.identity,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
            }
        );
    }

    function FacturacionHogaresService($resource) {
        return $resource(
            resourceUrlAdministrador + "/homologar/periodo/:id/hogares",
            {},
            {
                upload: {
                    method: "POST",
                    timeout: 30 * 60 * 1000,
                    headers: {
                        "Content-Type": undefined,
                        "Access-Control-Request-Headers":
                            "accept, origin, x-requested-with",
                        "Access-Control-Request-Method":
                            "POST, PUT, GET, DELETE",
                    },
                    transformRequest: angular.identity,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
            }
        );
    }

    function FacturacionGrandesClientesService($resource) {
        return $resource(
            resourceUrlAdministrador +
                "/homologar/periodo/:id/grandes-clientes",
            {},
            {
                upload: {
                    method: "POST",
                    timeout: 30 * 60 * 1000,
                    headers: {
                        "Content-Type": undefined,
                        "Access-Control-Request-Headers":
                            "accept, origin, x-requested-with",
                        "Access-Control-Request-Method":
                            "POST, PUT, GET, DELETE",
                    },
                    transformRequest: angular.identity,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
            }
        );
    }

    function FacturacionEstadoService($resource) {
        return $resource(
            "http://200.58.99.70/facturador/ConsultarMaxArchivosLote",
            {},
            {
                check: {
                    method: "GET",
                },
            }
        );
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('InternoController', InternoController);

    InternoController.$inject = ['$state', 'Interno', 'InternoSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','NotifierMailSocio'];

    function InternoController($state, Interno, InternoSearch, ParseLinks, AlertService, paginationConstants, pagingParams,NotifierMailSocio) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchNumeroInterno = '';
        vm.searchDomicilio = '';
        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                InternoSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Interno.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.internos = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search() {

            Interno.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                'numeroInterno.equals': vm.searchNumeroInterno,
                'domicilioSuministro.contains' : vm.searchDomicilio

            }, onSuccess, onError);
        
      

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.internos = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

 

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchNumeroInterno = '';
            vm.searchDomicilio = '';
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('NuevosUsuariosDetailController', NuevosUsuariosDetailController);

    NuevosUsuariosDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'NuevosUsuarios'];

    function NuevosUsuariosDetailController($scope, $rootScope, $stateParams, previousState, entity, NuevosUsuarios) {
        var vm = this;

        vm.nuevosUsuarios = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:nuevosUsuariosUpdate', function(event, result) {
            vm.nuevosUsuarios = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('InternoGrandesClientesDetailController', InternoGrandesClientesDetailController);

    InternoGrandesClientesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InternoGrandesClientes','FacturaGrandesClientes', 'FacturaGrandesClientesAgua','FacturaGrandesPDF', 'FacturaGrandesClientesEnergia', 'Cliente','FacturaEnergiaPDF','FacturaAguaPDF','FileSaver'];

    function InternoGrandesClientesDetailController($scope, $rootScope, $stateParams, previousState, entity, InternoGrandesClientes,FacturaGrandesClientes ,FacturaGrandesClientesAgua,FacturaGrandesPDF, FacturaGrandesClientesEnergia, Cliente,FacturaEnergiaPDF,FacturaAguaPDF,FileSaver) {
        var vm = this;
        vm.descargarFacturaAgua=descargarFacturaAgua;
        vm.descargarFacturaEnergia=descargarFacturaEnergia;
        vm.descargarFactura=descargarFactura;


        vm.internoGrandesClientes = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:internoGrandesClientesUpdate', function(event, result) {
            vm.internoGrandesClientes = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        
        FacturaGrandesClientes.query({
             "internoGrandesClientesId.equals":  vm.internoGrandesClientes.id,
             size: vm.itemsPerPage,
             sort: "fechaEmision,desc",
         }, onSuccessGc, onErrorGc);
     
         function onSuccessGc(data, headers) {
             // vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.facturasGc = data;
         }
         function onErrorGc(error) {
             AlertService.error(error.data.message);
         }
        
        
        FacturaGrandesClientesAgua.query({
           // page: pagingParams.page - 1,
            "internoGrandesClientesId.equals":  vm.internoGrandesClientes.id,
            size: vm.itemsPerPage,
            sort: "fechaEmision,desc",
        }, onSuccessAgua, onErrorAgua);
    
        function onSuccessAgua(data, headers) {
            // vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.facturasAgua = data;
            // vm.page = pagingParams.page;
        }
        function onErrorAgua(error) {
            AlertService.error(error.data.message);
        }
        
        FacturaGrandesClientesEnergia.query({
            // page: pagingParams.page - 1,
             "internoGrandesClientesId.equals":  vm.internoGrandesClientes.id,
             size: vm.itemsPerPage,
             sort: "fechaEmision,desc",
         }, onSuccessEnergia, onErrorEnergia);
     
         function onSuccessEnergia(data, headers) {
             // vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.facturasEnergia = data;
             // vm.page = pagingParams.page;
         }
         function onErrorEnergia(error) {
             AlertService.error(error.data.message);
         }
         
         function descargarFactura(facturaDto) {
  			vm.isSending = true;					
  			FacturaGrandesPDF.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {			                       
                   FileSaver.saveAs(res.response, facturaDto.fechaEmision+'-factura.pdf');			        
  	        }).catch(function (err) {
  				vm.isSending = false;
  	        });			
  		}
  		
         
         
         function descargarFacturaAgua(facturaDto) {
 			vm.isSending = true;					
 			FacturaAguaPDF.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {			                       
                  FileSaver.saveAs(res.response, facturaDto.fechaEmision+'-factura.pdf');			        
 	        }).catch(function (err) {
 				vm.isSending = false;
 	        });			
 		}
 		function onPFDSuccessAgua(data, headers) {

 			var url = URL.createObjectURL(data.response);
 			var printwWindow = $window.open(url);
 			printwWindow.print();

 		}
 		function onPDFErrorAgua(error) {
 			AlertService.error(error.data.message);
 		}

 		function descargarFacturaEnergia(facturaDto) {
 			vm.isSending = true;					
 			FacturaEnergiaPDF.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {			                       
                  FileSaver.saveAs(res.response, facturaDto.fechaEmision+'-factura.pdf');			        
 	        }).catch(function (err) {
 				vm.isSending = false;
 	        });		
 		}
 		function onPFDSuccessEnergia(data, headers) {

 			var url = URL.createObjectURL(data.response);
 			var printwWindow = $window.open(url);
 			printwWindow.print();

 		}
 		function onPDFErrorEnergia(error) {
 			AlertService.error(error.data.message);
 		}
                
        
    }
})();

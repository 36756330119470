(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('Importer', Importer).factory('ImporterGrandes', ImporterGrandes).factory('ImporterHogares', ImporterHogares).factory('ImporterEnergia', ImporterEnergia).factory('ImporterDeuda', ImporterDeuda).factory('ImporterPaga', ImporterPaga)
        .config(['$httpProvider', function($httpProvider) {
		    $httpProvider.defaults.timeout = 200000;
		}]);

    Importer.$inject = ['$resource'];
    ImporterGrandes.$inject = ['$resource'];
    ImporterEnergia.$inject = ['$resource'];
    ImporterPaga.$inject = ['$resource'];
    ImporterDeuda.$inject = ['$resource'];
    ImporterHogares.$inject = ['$resource'];

    var resourceUrl =  'importerv3/' + 'api/importador/importar-archivo';
    var resourceUrlGrandes =  'importerv3/' + 'api/importador/importar-grandes-clientes';
    var resourceUrlEnergia =  'importerv3/' + 'api/importador/importar-grandes-energia';
    var resourceUrlHogares =  'importerv3/' + 'api/importador/importar-facturas-dos';
    var resourceUrlPaga =  'importerv3/' + 'api/importador/importar-pagas-lotes';
    var resourceUrlDeuda =  'importerv3/' + 'api/importador/importar-deuda';
    
    function Importer ($resource) {
        return $resource(resourceUrl, {}, {
        	'upload': {
                method : 'POST',
                timeout: 30 * 60 * 1000,
                headers : {
                    'Content-Type': undefined,
                    'Access-Control-Request-Headers': 'accept, origin, x-requested-with',
                    'Access-Control-Request-Method': 'POST, PUT, GET, DELETE'
                },        
                transformRequest: angular.identity,
        		transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
	        
        });
    }
    
    function ImporterDeuda ($resource) {
        return $resource(resourceUrlDeuda, {}, {
        	'upload': {
                method : 'POST',
                timeout: 30 * 60 * 1000,
                headers : {
                    'Content-Type': undefined,
                    'Access-Control-Request-Headers': 'accept, origin, x-requested-with',
                    'Access-Control-Request-Method': 'POST, PUT, GET, DELETE'
                },        
                transformRequest: angular.identity,
        		transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
	        
        });
    }
    
    function ImporterPaga ($resource) {
        return $resource(resourceUrlPaga, {}, {
        	'upload': {
                method : 'POST',
                timeout: 30 * 60 * 1000,
                headers : {
                    'Content-Type': undefined,
                    'Access-Control-Request-Headers': 'accept, origin, x-requested-with',
                    'Access-Control-Request-Method': 'POST, PUT, GET, DELETE'
                },        
                transformRequest: angular.identity,
        		transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
	        
        });
    }
    
    function ImporterGrandes ($resource) {
        return $resource(resourceUrlGrandes, {}, {
        	'upload': {
                method : 'POST',
                timeout: 30 * 60 * 1000,
                headers : {
                    'Content-Type': undefined,
                    'Access-Control-Request-Headers': 'accept, origin, x-requested-with',
                    'Access-Control-Request-Method': 'POST, PUT, GET, DELETE'
                },        
                transformRequest: angular.identity,
        		transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
	        
        });
    }
    
    function ImporterHogares ($resource) {
        return $resource(resourceUrlHogares, {}, {
        	'upload': {
                method : 'POST',
                timeout: 30 * 60 * 100000,
                headers : {
                    'Content-Type': undefined,
                    'Access-Control-Request-Headers': 'accept, origin, x-requested-with',
                    'Access-Control-Request-Method': 'POST, PUT, GET, DELETE'
                },        
                transformRequest: angular.identity,
        		transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
	        
        });
    }
    
    function ImporterEnergia ($resource) {
        return $resource(resourceUrlEnergia, {}, {
        	'upload': {
                method : 'POST',
                timeout: 30 * 60 * 1000,
                headers : {
                    'Content-Type': undefined,
                    'Access-Control-Request-Headers': 'accept, origin, x-requested-with',
                    'Access-Control-Request-Method': 'POST, PUT, GET, DELETE'
                },        
                transformRequest: angular.identity,
        		transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
	        
        });
    }
})();

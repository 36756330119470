(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .factory('Register', Register).factory('RegisterSocio', RegisterSocio).factory('RegisterNoTitular', RegisterNoTitular).factory('RegisterSinFactura', RegisterSinFactura).factory('RegisterCuentaNoTitular', RegisterCuentaNoTitular);

    Register.$inject = ['$resource'];
    RegisterSocio.$inject = ['$resource'];
    RegisterNoTitular.$inject = ['$resource'];
    RegisterSinFactura.$inject = ['$resource'];
    RegisterCuentaNoTitular.$inject = ['$resource'];

    
    var resourceUrlSocio =  'api/cooperativa/socio/nuevo';
    var resourceUrlNoTitular =  'api/cooperativa/no-titular/nuevo';
    var resourceUrlSinFactura =  'api/cooperativa/sin-factura/nuevo';
    
    function Register ($resource) {
        return $resource('api/register', {}, {});
    }

    function RegisterCuentaNoTitular ($resource) {
        return $resource('api/registerNoTitular', {}, {});
    }
    
    function RegisterSocio ($resource) {
        return $resource(resourceUrlSocio, {}, {});
    }
    
    function RegisterNoTitular ($resource) {
        return $resource(resourceUrlNoTitular, {}, {});
    }

	function RegisterSinFactura ($resource) {
        return $resource(resourceUrlSinFactura, {}, {});
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturaGrandesClientesEnergiaDetailController', FacturaGrandesClientesEnergiaDetailController);

    FacturaGrandesClientesEnergiaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FacturaGrandesClientesEnergia', 'DetalleFacturaMora', 'DetalleFacturaGrandesClientes', 'InternoGrandesClientes'];

    function FacturaGrandesClientesEnergiaDetailController($scope, $rootScope, $stateParams, previousState, entity, FacturaGrandesClientesEnergia, DetalleFacturaMora, DetalleFacturaGrandesClientes, InternoGrandesClientes) {
        var vm = this;

        vm.facturaGrandesClientesEnergia = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:facturaGrandesClientesEnergiaUpdate', function(event, result) {
            vm.facturaGrandesClientesEnergia = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
	'use strict';
	angular.module('coopFrontEndApp').factory('Notifier', Notifier).factory('NotifierFacturaMail', NotifierFacturaMail).factory('NotifierMailHogares', NotifierMailHogares).factory('NotifierSMS', NotifierSMS).factory('NotifierMails', NotifierMails)
	.factory('NotifierMailSocio', NotifierMailSocio).factory('NotifierMailsFeErratas', NotifierMailsFeErratas).factory('NotifierMailsGrandesClientes', NotifierMailsGrandesClientes).factory('NotifierPushSocio', NotifierPushSocio);

	Notifier.$inject = [ '$resource' ];
	NotifierSMS.$inject = [ '$resource' ];
	NotifierMails.$inject = [ '$resource' ];
	NotifierMailsGrandesClientes.$inject = [ '$resource' ];
	NotifierMailSocio.$inject = [ '$resource' ];
	NotifierPushSocio.$inject = [ '$resource' ];
	NotifierMailHogares.$inject = [ '$resource' ];
	NotifierFacturaMail.$inject = [ '$resource' ];
	NotifierMailsFeErratas.$inject = [ '$resource' ];


	var resourceUrl = 'importerv3/' + 'api/cliente/celulares';
	var resourceUrlMails = 'importerv3/' + 'api/administrador/enviar-facturas';
	var resourceUrlMailsGc = 'importerv3/' + 'api/notificador/enviar-mail-pdf-grandes-clientes';
	var resourceUrlSMS = 'importerv3/' + 'api/notificador/enviar-sms?nsc=:nsc&body=:body';
	var resourceUrlMailHogares = 'importerv3/' + 'api/notificador/enviar-mail-pdf-hogares-vencimiento?fecha=:fecha';
	var resourceUrlPushSocio = 'importerv3/' + 'api/notificador/enviar-push-socio?loginName=:loginName&mensaje=:mensaje';
	var resourceUrlFacturaMailSocio = 'importerv3/' + 'api/notificador/enviar-factira-mail?idFactura=:idFactura&mail=:mail';
	var resourceUrlFeErratas = 'importerv3/' + 'api/helper/enviar-mail-fe-erratas-sepelios';



	function Notifier($resource) {
		return $resource(resourceUrl, {}, {
			'get_celulares' : {
				method : 'GET',
				headers : {
					'Accept' : 'text/plain, text/xml',
					'Content-Type' : 'text/xml'
				},
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}
	
	function NotifierSMS($resource) {
		return $resource(resourceUrlSMS, {
			nsc : "@nsc",
			body : "@body"
		}, {
			'send_sms' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}
	
	function NotifierMailSocio($resource) {
		return $resource(resourceUrlFacturaMailSocio,  {
			'send_mail' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}



	function NotifierFacturaMail($resource) {
		return $resource(resourceUrlFacturaMailSocio, {
			idFactura : "@idFactura",
			mail : "@mail"
		}, {
			'send_mail_factura' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}

	
	function NotifierMailHogares($resource) {
		return $resource(resourceUrlMailHogares, {
			fecha : "@fecha"
		}, {
			'send_mail' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}
	
	function NotifierPushSocio($resource) {
		return $resource(resourceUrlPushSocio, {
			loginName : "@loginName",
			mensaje : "@mensaje"
		}, {
			'send_push' : {
				method : 'POST',
				timeout: 0,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}

	function NotifierMails($resource) {
		return $resource(resourceUrlMails, {}, {
			'send_mails' : {
				method : 'POST',
				timeout: 30 * 60 * 1000,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}
	
	function NotifierMailsGrandesClientes($resource) {
		return $resource(resourceUrlMailsGc, {}, {
			'send_mails' : {
				method : 'POST',
				timeout: 30 * 60 * 1000,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}

	function NotifierMailsFeErratas($resource) {
		return $resource(resourceUrlFeErratas, {}, {
			'send_mails' : {
				method : 'GET',
				timeout: 30 * 60 * 1000,
				transformRequest : angular.identity,
				transformResponse : function(data) {
					return {
						responseData : data.toString()
					}
				}
			}
		});
	}
})();

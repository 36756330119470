(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('NotifierController', NotifierController);

    NotifierController.$inject = ['$state'];

    function NotifierController($state) {
        var vm = this;
    }
})();

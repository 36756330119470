(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller(
            "FacturacionPeriodoDetailController",
            FacturacionPeriodoDetailController
        );

    FacturacionPeriodoDetailController.$inject = [
        "$scope",
        "$rootScope",
        "previousState",
        "entity",
    ];

    function FacturacionPeriodoDetailController(
        $scope,
        $rootScope,
        previousState,
        entity
    ) {
        var vm = this;

        $scope.tab = 1;
        vm.periodo = entity;
        vm.previousState = previousState.name;

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
        };

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        var unsubscribe = $rootScope.$on(
            "coopFrontEndApp:periodoUpdate",
            function (event, result) {
                vm.periodo = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();

(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller("InternoBeneficioController", InternoBeneficioController);

    InternoBeneficioController.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "$uibModal",
        "DataUtils",
        "previousState",
        "entity",
        "AlertService",
        "InternoBeneficio",
        
    ];

    function InternoBeneficioController(
        $scope,
        $rootScope,
        $state,
        $uibModal,
        DataUtils,
        previousState,
        entity,
        AlertService,
        InternoBeneficio
    ) {
        var vm = this;

        vm.interno = entity;
        vm.previousState = previousState.name;

        $scope.rubros = [
            "Albergues transitorios",
            "Bancos",
            "Bares",
            "Billares",
            "Cabarets",
            "Cafés",
            "Carpinterías",
            "Casa Velatorias",
            "Casinos",
            "Cocheras",
            "Consultorios Privados",
            "Corralones",
            "Depósitos ",
            "Despacho de Bebidas",
            "Elaboración de Helados",
            "Elaboración de Pastas y/o pan",
            "Elaboración de Pescado",
            "Establecimientos Textiles",
            "Estación de Servicio con Lavadero",
            "Estudios Profesionales",
            "Fábrica de Bebidas",
            "Fábrica de Hielo",
            "Fábrica de Ladrillos",
            "Fábrica de Productos Químicos",
            "Fábricas de Pre – Moldeados",
            "Farmacias",
            "Galpones",
            "Gimnasios",
            "Heladerías",
            "Hogar de Ancianos",
            "Institutos Privados de Educación",
            "Internados",
            "Lavadero de Autos",
            "Lavaderos de Lana",
            "Locales que comparten los servicios con viviendas",
            "Locutorios",
            "Mataderos",
            "Oficinas Comerciales",
            "Oficinas Públicas Nacionales o Provinciales con baja demanda de servicio",
            "Oficinas Públicas Nacionales o Provinciales con gran demanda de servicio",
            "Peloteros",
            "Peluquerías",
            "Piletas de Natación",
            "Pizzerias",
            "Radiodifusoras",
            "Rotiserías",
            "Salas de entretenimiento",
            "Salones de Baile",
            "Talleres",
            "Televisoras",
            "Tintorerias",
            "Viveros Comerciales",
            "Wiskerías",
            "OTROS",
        ];

        $scope.error = null;
        $scope.aplicaInterno = false;
        $scope.volver = null;
        $scope.file = '';


        $scope.ayudaFinancieraMeses = 6;
        
        vm.datos = {
            numeroInterno: vm.interno.numeroInterno,
            ayudaEconomica: false,
            ayudaFinanciera: false,
            ayudaClubes: false,
            seis: true,
            doce: false,
            rubro: "",
            celular: "",
            email: "",
            documentacion: [],
        };
        
        vm.bene={
        		base64: null,
        		fileyype: null,
        		filename: null,
        		fileSize: null
            };
        
        
        

        vm.myfile = {};

        var unsubscribe = $rootScope.$on("coopFrontEndApp:internoUpdate",
            function (event, result) {
                vm.interno = result;
            }
        );

        $scope.$on("$destroy", unsubscribe);

        $scope.selectedAyudaFinancieraMeses = function (seis, doce) {
            vm.datos.seis = seis;
            vm.datos.doce = doce;
        };
        
        
        $scope.remove = function(item) { 
        	  var index = vm.datos.documentacion.indexOf(item);
        	  vm.datos.documentacion.splice(index, 1);     
        	}
        
        
        
        vm.setArchivo = function ($file, bene) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        bene.base64 = base64Data;
                        bene.fileyype = $file.type;
                        bene.fileSize = $file.type;
                        bene.filename = $file.name;    
                        vm.datos.documentacion.push(bene);
                        vm.bene={
                        		base64: null,
                        		fileyype: null,
                        		filename: null,
                        		fileSize: null
                            };
                    });
                });
            }
        };

        $scope.aplicarBeneficio = function () {
            console.log("guardo", vm.datos);          
            InternoBeneficio.aplicar(vm.datos, onSaveSuccess, onSaveError);
        };
        
        $scope.volver = function () {
        	 $state.go("home");
        };

        $scope.fileChanged = function ($event) {
            var files = $event.target.files;
            console.log(files);
        };

        $scope.setFileData = function (event, field) {
            console.log("aca estoy", event, field);

        };

        function onSaveSuccess(result) {
            $scope.$emit("coopFrontEndApp:InternoBeneficio", result);
            vm.isSaving = false;
            
            $uibModal
            .open({
                templateUrl:
                    "app/entities/interno/beneficio-dialog-succes.html",
                controller: "InternoDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                    entity: function () {
                        return {
                            numeroInterno: null,
                            domicilioSuministro: null,
                            domicilioPostal: null,
                            adheridoFactura: null,
                            activo: null,
                            id: null,
                        };
                    },
                },
            })
            .result.then(
                function () {
                    $state.go("interno", null, {
                        reload: "home",
                    });
                },
                function () {
                    $state.go("home");
                }
            );
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

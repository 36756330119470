(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('interno-grandes-clientes', {
            parent: 'entity',
            url: '/interno-grandes-clientes?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN',"ROLE_COOP"],
                pageTitle: 'coopFrontEndApp.internoGrandesClientes.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/interno-grandes-clientes/interno-grandes-clientes.html',
                    controller: 'InternoGrandesClientesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('internoGrandesClientes');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('interno-grandes-clientes-detail', {
            parent: 'interno-grandes-clientes',
            url: '/interno-grandes-clientes/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_ATENCION'],
                pageTitle: 'coopFrontEndApp.internoGrandesClientes.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/interno-grandes-clientes/interno-grandes-clientes-detail.html',
                    controller: 'InternoGrandesClientesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('internoGrandesClientes');
                    $translatePartialLoader.addPart('facturaGrandesClientesAgua');
                    $translatePartialLoader.addPart('facturaGrandesClientesEnergia');
                    $translatePartialLoader.addPart('facturaGrandesClientes');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'InternoGrandesClientes', function($stateParams, InternoGrandesClientes) {
                    return InternoGrandesClientes.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'interno-grandes-clientes',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('interno-grandes-clientes-detail.edit', {
            parent: 'interno-grandes-clientes-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_COOP']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interno-grandes-clientes/interno-grandes-clientes-dialog.html',
                    controller: 'InternoGrandesClientesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InternoGrandesClientes', function(InternoGrandesClientes) {
                            return InternoGrandesClientes.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('interno-grandes-clientes.new', {
            parent: 'interno-grandes-clientes',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interno-grandes-clientes/interno-grandes-clientes-dialog.html',
                    controller: 'InternoGrandesClientesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                numeroInterno: null,
                                domicilioSuministro: null,
                                adheridoFactura: null,
                                activo: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('interno-grandes-clientes', null, { reload: 'interno-grandes-clientes' });
                }, function() {
                    $state.go('interno-grandes-clientes');
                });
            }]
        })
        .state('interno-grandes-clientes.edit', {
            parent: 'interno-grandes-clientes',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interno-grandes-clientes/interno-grandes-clientes-dialog.html',
                    controller: 'InternoGrandesClientesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InternoGrandesClientes', function(InternoGrandesClientes) {
                            return InternoGrandesClientes.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('interno-grandes-clientes', null, { reload: 'interno-grandes-clientes' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('interno-grandes-clientes.delete', {
            parent: 'interno-grandes-clientes',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interno-grandes-clientes/interno-grandes-clientes-delete-dialog.html',
                    controller: 'InternoGrandesClientesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InternoGrandesClientes', function(InternoGrandesClientes) {
                            return InternoGrandesClientes.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('interno-grandes-clientes', null, { reload: 'interno-grandes-clientes' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
//        .state('factura-gc.send-email-socio', {
//                parent: 'interno-grandes-clientes-detail',
//                url: '/{idFactura}/enviar-mail-gc',
//                data: {
//                    authorities: ['ROLE_ADMIN','ROLE_COOP']
//                },
//                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                    $uibModal.open({
//                        templateUrl: 'app/entities/interno-grandes-clientes/send-factura-to-socio-dialog.html',
//                        controller: 'NotifierDialogController',
//                        controllerAs: 'vm',
//                        backdrop: 'static',
//                        size: 'md'
//                    }).result.then(function() {
//                        $state.go('interno-grandes-clientes-detail', null, { reload: 'notifier' });
//                    }, function() {
//                        $state.go('interno-grandes-clientes-detail');
//                    });
//                }],
//                resolve: {
//                    translatePartialLoader: ["$translate","$translatePartialLoader",
//                        function ($translate, $translatePartialLoader) {
//                            $translatePartialLoader.addPart("interno");
//                            return $translate.refresh();
//                        },
//                    ]}
//        })
    }

})();

(function () {
    "use strict";

    angular.module("coopFrontEndApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("atencion", {
                parent: "app",
                url: "/atencion",
                data: {
                    authorities: ["ROLE_ATENCION"],
                    pageTitle: "coopFrontEndApp.atencion.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/atencion/atencion.html",
                        controller: "AtencionController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    authorize: [
                        "Auth",
                        function (Auth) {
                            return Auth.authorize();
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("atencion");
                            $translatePartialLoader.addPart("periodo");
							$translatePartialLoader.addPart('notificador');
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state('atencion-cliente', {
                parent: 'atencion',
                url: '/cliente?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_ATENCION'],
                    pageTitle: 'coopFrontEndApp.cliente.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/cliente/clientes.html',
                        controller: 'ClienteController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cliente');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('atencion-cliente-detail', {
                parent: 'atencion-cliente',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_FACTURACION','ROLE_ATENCION'],
                    pageTitle: 'coopFrontEndApp.cliente.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/cliente/cliente-detail.html',
                        controller: 'ClienteDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cliente');
                        $translatePartialLoader.addPart('interno');
                        $translatePartialLoader.addPart('internoGrandesClientes');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Cliente', function($stateParams, Cliente) {
                        return Cliente.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'cliente',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state("atencion-cliente-interno-detail", {
                parent: "atencion-cliente-detail",
                url: "/atencion-interno/{id}",
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_FACTURACION','ROLE_ATENCION'],
                    pageTitle: "coopFrontEndApp.interno.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/interno/interno-detail.html",
                        controller: "InternoDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("interno");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "Interno",
                        function ($stateParams, Interno) {
                            return Interno.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "interno",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("atencion-interno", {
                parent: "atencion",
                url: "/atencion-interno/interno?page&sort&search",
                data: {
                    authorities: ["ROLE_ADMIN","ROLE_ATENCION"],
                    pageTitle: "coopFrontEndApp.interno.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/interno/internos.html",
                        controller: "InternoController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("interno");
                            $translatePartialLoader.addPart("global");
                            $translatePartialLoader.addPart("factura");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state('atencion-interno-grandes-clientes', {
                parent: 'atencion',
                url: '/atencion-interno-grandes-clientes?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN',"ROLE_ATENCION"],
                    pageTitle: 'coopFrontEndApp.internoGrandesClientes.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/interno-grandes-clientes/interno-grandes-clientes.html',
                        controller: 'InternoGrandesClientesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('internoGrandesClientes');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturaGrandesClientesAguaDialogController', FacturaGrandesClientesAguaDialogController);

    FacturaGrandesClientesAguaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FacturaGrandesClientesAgua', 'DetalleFacturaGrandesClientes', 'DetalleFacturaMora', 'InternoGrandesClientes'];

    function FacturaGrandesClientesAguaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FacturaGrandesClientesAgua, DetalleFacturaGrandesClientes, DetalleFacturaMora, InternoGrandesClientes) {
        var vm = this;

        vm.facturaGrandesClientesAgua = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.detallefacturagrandesclientes = DetalleFacturaGrandesClientes.query();
        vm.detallefacturamoras = DetalleFacturaMora.query();
        vm.internograndesclientes = InternoGrandesClientes.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.facturaGrandesClientesAgua.id !== null) {
                FacturaGrandesClientesAgua.update(vm.facturaGrandesClientesAgua, onSaveSuccess, onSaveError);
            } else {
                FacturaGrandesClientesAgua.save(vm.facturaGrandesClientesAgua, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('coopFrontEndApp:facturaGrandesClientesAguaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.fechaLecturaActualUno = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorUno = false;
        vm.datePickerOpenStatus.fechaLecturaActualDos = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorDos = false;
        vm.datePickerOpenStatus.fechaLecturaActualTres = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorTres = false;
        vm.datePickerOpenStatus.fechaLecturaActualCuatro = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorCuatro = false;
        vm.datePickerOpenStatus.fechaLecturaActualCinco = false;
        vm.datePickerOpenStatus.fechaLecturaAnteriorCinco = false;
        vm.datePickerOpenStatus.fechaVencimiento = false;
        vm.datePickerOpenStatus.fechaProximoVencimiento = false;
        vm.datePickerOpenStatus.fechaEmision = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

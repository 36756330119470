(function () {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('ClienteFacturaController', ClienteFacturaController);

    ClienteFacturaController.$inject = ['$state', '$scope', '$stateParams', '$window','ClienteFacturaDos','HogaresPdf', 'ClienteFacturaPdf','ClienteFacturaPagar', 'ClienteFactura', 'FacturaSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'FileSaver','ClienteFacturaHistorico','Interno','Cliente','previousState'];

    function ClienteFacturaController($state, $scope, $stateParams, $window,ClienteFacturaDos,HogaresPdf, ClienteFacturaPdf,ClienteFacturaPagar, ClienteFactura, FacturaSearch, ParseLinks, AlertService, paginationConstants, pagingParams, FileSaver,ClienteFacturaHistorico,Interno,Cliente,previousState) {

        var vm = this;
      
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 12;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.descargarPdf = descargarPdf;
        vm.pagarFactura = pagarFactura;
        vm.periodos = [];
        vm.consumos = [];
        vm.ultimaFactura = null;
        vm.interno = null;
        vm.socio = null;
        vm.previousState = previousState.name;
       


        //loadAll();
        getFacturas();
     
      
      
        function getFacturas() {

            ClienteFacturaDos.get({id : $stateParams.id}).$promise.then(
                //success
                function (data) {
                    vm.facturas = data;
                    vm.ultimaFactura = vm.facturas [0];
                    ClienteFacturaHistorico.get({idFactura : vm.ultimaFactura.id},onSuccessHistorico);
                    Interno.get({id : vm.ultimaFactura.internoId},onSuccessInterno);


                },
                //error
                function (error) {/*Do something with error*/ }
            );
        }

        function loadAll() {            

            ClienteFactura.query({
                page: pagingParams.page - 1,
                "internoId.equals": $stateParams.id,
                size: vm.itemsPerPage,
                sort: "fechaEmision,desc",
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.facturas = data;
                vm.page = pagingParams.page;
                vm.ultimaFactura = vm.facturas [0];
                ClienteFacturaHistorico.get({idFactura : vm.ultimaFactura.id},onSuccessHistorico);
                Interno.get({id : vm.ultimaFactura.internoId},onSuccessInterno);
               
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function onSuccessHistorico(data) {
            angular.forEach(data, function(value, key){
                vm.periodos.push(value.periodo);
                vm.consumos.push(value.consumo);
            });

            vm.periodos.reverse();
            vm.consumos.reverse();
            $scope.series = ['Series A'];
        }

        function onSuccessInterno(data) {
            vm.interno = data;
            Cliente.get({id : vm.interno.clienteId},onSuccessSocio);
        }

        function onSuccessSocio(data) {
            vm.socio = data;
        }


        function descargarPdf(facturaDto) {
            vm.isSending = true;
            
            if (facturaDto.modeloDos){
            	HogaresPdf.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {				
                    
	                 FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString()+'-'+facturaDto.fechaEmision + '.pdf');
	                 vm.isSending = false;

				        
		        }).catch(function (err) {
					vm.isSending = false;
		        });		
            }
            else{
            	 ClienteFacturaPdf.descargar({facturaId : facturaDto.id }).$promise.then(function (res) {
                     FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString()+'-'+facturaDto.fechaEmision + '.pdf');
                     vm.isSending = false;
                 }).catch(function (err) {
                     vm.isSending = false;
                 });
            }
           
        }
        
        function pagarFactura(facturaDto) {
            vm.isSending = true;
            ClienteFacturaPagar.post({id : facturaDto.id},onSuccessPagar);
        }
        function onSuccessPagar(data) {        
            //$window.open(data.initPoint, '_blank');
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('ClienteFacturaGrandesClientesEnergia', ClienteFacturaGrandesClientesEnergia);

    ClienteFacturaGrandesClientesEnergia.$inject = ['$resource', 'DateUtils'];

    function ClienteFacturaGrandesClientesEnergia ($resource, DateUtils) {
        var resourceUrl =  'importerv3/' + 'api/cliente/factura-grandes-clientes-energias/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fechaEmision = DateUtils.convertLocalDateFromServer(data.fechaEmision);
                        data.fechaLecturaActualEnergiaActiva = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualEnergiaActiva);
                        data.fechaLecturaAnteriorEnergiaActiva = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorEnergiaActiva);
                        data.fechaLecturaActualEnergiaReactiva = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualEnergiaReactiva);
                        data.fechaLecturaAnteriorEnergiaReactiva = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorEnergiaReactiva);
                        data.fechaVencimiento = DateUtils.convertLocalDateFromServer(data.fechaVencimiento);
                        data.fechaProximoVencimiento = DateUtils.convertLocalDateFromServer(data.fechaProximoVencimiento);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    copy.fechaLecturaActualEnergiaActiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualEnergiaActiva);
                    copy.fechaLecturaAnteriorEnergiaActiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorEnergiaActiva);
                    copy.fechaLecturaActualEnergiaReactiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualEnergiaReactiva);
                    copy.fechaLecturaAnteriorEnergiaReactiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorEnergiaReactiva);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximoVencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximoVencimiento);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    copy.fechaLecturaActualEnergiaActiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualEnergiaActiva);
                    copy.fechaLecturaAnteriorEnergiaActiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorEnergiaActiva);
                    copy.fechaLecturaActualEnergiaReactiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualEnergiaReactiva);
                    copy.fechaLecturaAnteriorEnergiaReactiva = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorEnergiaReactiva);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximoVencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximoVencimiento);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();

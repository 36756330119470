(function () {
    "use strict";

    angular
        .module("coopFrontEndApp")
        .controller(
            "FacturacionPeriodoOpenDialogController",
            FacturacionPeriodoOpenDialogController
        );

    FacturacionPeriodoOpenDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "entity",
        "FacturacionPeriodoService",
    ];

    function FacturacionPeriodoOpenDialogController(
        $timeout,
        $scope,
        $stateParams,
        $uibModalInstance,
        entity,
        FacturacionPeriodoService
    ) {
        var vm = this;

        vm.periodo = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.open = open;

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function open() {
            vm.isSaving = true;
            FacturacionPeriodoService.open(
                vm.periodo,
                onSaveSuccess,
                onSaveError
            );
        }

        function onSaveSuccess(result) {
            $scope.$emit("coopFrontEndApp:periodoUpdate", result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('importer', {
            parent: 'admin',
            url: '/importer?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'coopFrontEndApp.registro.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/importer/importer.html',
                    controller: 'ImporterController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('registro');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('importer.upload', {
            parent: 'importer',
            url: '/upload',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/importer/importer-dialog.html',
                    controller: 'ImporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('importer', null, { reload: 'importer' });
                }, function() {
                    $state.go('importer');
                });
            }]
        })
        .state('importer.upload-energia', {
            parent: 'importer',
            url: '/upload-energia',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/importer/importer-dialog-energia.html',
                    controller: 'ImporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('importer', null, { reload: 'importer' });
                }, function() {
                    $state.go('importer');
                });
            }]
        })
        .state('importer.upload-grandes', {
            parent: 'importer',
            url: '/upload-grandes',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/importer/importer-dialog-grandes.html',
                    controller: 'ImporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('importer', null, { reload: 'importer' });
                }, function() {
                    $state.go('importer');
                });
            }]
        })
        
        .state('importer.upload-hogares', {
            parent: 'importer',
            url: '/upload-hogares',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/importer/importer-dialog-hogares.html',
                    controller: 'ImporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('importer', null, { reload: 'importer' });
                }, function() {
                    $state.go('importer');
                });
            }]
        })
        .state('importer.upload-pagas', {
            parent: 'importer',
            url: '/upload-pagas',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/importer/importer-dialog-pagas.html',
                    controller: 'ImporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('importer', null, { reload: 'importer' });
                }, function() {
                    $state.go('importer');
                });
            }]
        })
        .state('importer.upload-deuda', {
            parent: 'importer',
            url: '/upload-deuda',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/importer/importer-dialog-deuda.html',
                    controller: 'ImporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('importer', null, { reload: 'importer' });
                }, function() {
                    $state.go('importer');
                });
            }]
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('DetalleInformacionDetailController', DetalleInformacionDetailController);

    DetalleInformacionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DetalleInformacion', 'Factura', 'FacturaGrandesClientes'];

    function DetalleInformacionDetailController($scope, $rootScope, $stateParams, previousState, entity, DetalleInformacion, Factura, FacturaGrandesClientes) {
        var vm = this;

        vm.detalleInformacion = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:detalleInformacionUpdate', function(event, result) {
            vm.detalleInformacion = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

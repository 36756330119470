(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('FacturaGrandesClientesAguaDetailController', FacturaGrandesClientesAguaDetailController);

    FacturaGrandesClientesAguaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FacturaGrandesClientesAgua', 'DetalleFacturaGrandesClientes', 'DetalleFacturaMora', 'InternoGrandesClientes'];

    function FacturaGrandesClientesAguaDetailController($scope, $rootScope, $stateParams, previousState, entity, FacturaGrandesClientesAgua, DetalleFacturaGrandesClientes, DetalleFacturaMora, InternoGrandesClientes) {
        var vm = this;

        vm.facturaGrandesClientesAgua = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:facturaGrandesClientesAguaUpdate', function(event, result) {
            vm.facturaGrandesClientesAgua = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

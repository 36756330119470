(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('factura-grandes-clientes-energia', {
            parent: 'entity',
            url: '/factura-grandes-clientes-energia?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'coopFrontEndApp.facturaGrandesClientesEnergia.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/factura-grandes-clientes-energia/factura-grandes-clientes-energias.html',
                    controller: 'FacturaGrandesClientesEnergiaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('facturaGrandesClientesEnergia');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('factura-grandes-clientes-energia-detail', {
            parent: 'factura-grandes-clientes-energia',
            url: '/factura-grandes-clientes-energia/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'coopFrontEndApp.facturaGrandesClientesEnergia.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/factura-grandes-clientes-energia/factura-grandes-clientes-energia-detail.html',
                    controller: 'FacturaGrandesClientesEnergiaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('facturaGrandesClientesEnergia');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'FacturaGrandesClientesEnergia', function($stateParams, FacturaGrandesClientesEnergia) {
                    return FacturaGrandesClientesEnergia.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'factura-grandes-clientes-energia',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('factura-grandes-clientes-energia-detail.edit', {
            parent: 'factura-grandes-clientes-energia-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/factura-grandes-clientes-energia/factura-grandes-clientes-energia-dialog.html',
                    controller: 'FacturaGrandesClientesEnergiaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FacturaGrandesClientesEnergia', function(FacturaGrandesClientesEnergia) {
                            return FacturaGrandesClientesEnergia.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('factura-grandes-clientes-energia.new', {
            parent: 'factura-grandes-clientes-energia',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/factura-grandes-clientes-energia/factura-grandes-clientes-energia-dialog.html',
                    controller: 'FacturaGrandesClientesEnergiaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                fechaEmision: null,
                                numeroFactura: null,
                                ruta: null,
                                folio: null,
                                cuit: null,
                                codigoPago: null,
                                tarifaEnergia: null,
                                descripcionTarifaEnergia: null,
                                cargoPotenciaMemsp: null,
                                cargoPotenciaFode: null,
                                cargoPotenciaVad: null,
                                cargoPotenciaTarifaFinal: null,
                                cargoVariableMemsp: null,
                                cargoVariableFode: null,
                                cargoVariableVad: null,
                                cargoVariableTarifaFinal: null,
                                potenciaConvenida: null,
                                potenciaRegistrada: null,
                                potenciaFacturada: null,
                                factorMultiplicacionUno: null,
                                tangente: null,
                                factorMultiplicacionDos: null,
                                recargoFactorPotencia: null,
                                numeroMedidorEnergiaActiva: null,
                                lecturaActualEnergiaActiva: null,
                                fechaLecturaActualEnergiaActiva: null,
                                lecturaAnteriorEnergiaActiva: null,
                                fechaLecturaAnteriorEnergiaActiva: null,
                                kwhAdicionalActiva: null,
                                totalConsumoEnergiaActiva: null,
                                numeroMedidorEnergiaReactiva: null,
                                lecturaActualEnergiaReactiva: null,
                                fechaLecturaActualEnergiaReactiva: null,
                                lecturaAnteriorEnergiaReactiva: null,
                                fechaLecturaAnteriorEnergiaReactiva: null,
                                kwhAdicionalReactiva: null,
                                totalConsumoEnergiaReactiva: null,
                                tienePeaje: null,
                                importeTotal: null,
                                periodoFacturado: null,
                                fechaVencimiento: null,
                                codigoDeBarraBanco: null,
                                codigoDeBarraCooperativa: null,
                                fechaProximoVencimiento: null,
                                totalDeuda: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('factura-grandes-clientes-energia', null, { reload: 'factura-grandes-clientes-energia' });
                }, function() {
                    $state.go('factura-grandes-clientes-energia');
                });
            }]
        })
        .state('factura-grandes-clientes-energia.edit', {
            parent: 'factura-grandes-clientes-energia',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/factura-grandes-clientes-energia/factura-grandes-clientes-energia-dialog.html',
                    controller: 'FacturaGrandesClientesEnergiaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FacturaGrandesClientesEnergia', function(FacturaGrandesClientesEnergia) {
                            return FacturaGrandesClientesEnergia.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('factura-grandes-clientes-energia', null, { reload: 'factura-grandes-clientes-energia' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('factura-grandes-clientes-energia.delete', {
            parent: 'factura-grandes-clientes-energia',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/factura-grandes-clientes-energia/factura-grandes-clientes-energia-delete-dialog.html',
                    controller: 'FacturaGrandesClientesEnergiaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['FacturaGrandesClientesEnergia', function(FacturaGrandesClientesEnergia) {
                            return FacturaGrandesClientesEnergia.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('factura-grandes-clientes-energia', null, { reload: 'factura-grandes-clientes-energia' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();

(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('PeriodoDetailController', PeriodoDetailController);

    PeriodoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Periodo', 'Lote', 'Factura', 'FacturaGrandesClientes'];

    function PeriodoDetailController($scope, $rootScope, $stateParams, previousState, entity, Periodo, Lote, Factura, FacturaGrandesClientes) {
        var vm = this;

        vm.periodo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('coopFrontEndApp:periodoUpdate', function(event, result) {
            vm.periodo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
	'use strict';

	angular.module('coopFrontEndApp').controller(
			'GrandesClienteFacturaController', GrandesClienteFacturaController);

	GrandesClienteFacturaController.$inject = [ '$state', '$stateParams',
			'$window', 'FacturaEnergiaPDF','FacturaAguaPDF','FacturaGrandesPDF','FileSaver',
			'ClienteFacturaGrandesClientesAgua','ClienteFacturaGrandesClientes',
			'ClienteFacturaGrandesClientesEnergia', 'FacturaSearch',
			'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','ClienteFacturaGrandesClientespagar' ];

	function GrandesClienteFacturaController($state, $stateParams, $window,
			FacturaEnergiaPDF,FacturaAguaPDF,FacturaGrandesPDF,FileSaver, ClienteFacturaGrandesClientesAgua,ClienteFacturaGrandesClientes,
			ClienteFacturaGrandesClientesEnergia, FacturaSearch, ParseLinks,
			AlertService, paginationConstants, pagingParams,ClienteFacturaGrandesClientespagar) {

		var vm = this;
		var newWindow;

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.search = search;
		vm.loadFacturas = loadFacturas;
		vm.loadAllAgua = loadAllAgua;
		vm.loadAllEnergia = loadAllEnergia;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;
		vm.descargarPdfAgua = descargarPdfAgua;
		vm.descargarPdfEnergia = descargarPdfEnergia;
		vm.descargarPdfGrandes = descargarPdfGrandes;
		vm.pagarFactura = pagarFactura;

		loadFacturas();
		loadAllAgua();
		loadAllEnergia();
		
		
		function loadFacturas() {
			if (pagingParams.search) {
				FacturaSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			} else {
				ClienteFacturaGrandesClientes.query({
					page : pagingParams.page - 1,
					"internoGrandesClientesId.equals" : $stateParams.id,
					size : 40,
					sort : "fechaEmision,desc",
				}, onSuccessFactura, onError);
			}
			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccessFactura(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.facturas = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadAllAgua() {
			if (pagingParams.search) {
				FacturaSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			} else {
				ClienteFacturaGrandesClientesAgua.query({
					page : pagingParams.page - 1,
					"internoGrandesClientesId.equals" : $stateParams.id,
					size : 6,
					sort : "fechaEmision,desc",
				}, onSuccessFacturaAgua, onError);
			}
			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccessFacturaAgua(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.facturasAgua = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadAllEnergia() {
			if (pagingParams.search) {
				FacturaSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			} else {
				ClienteFacturaGrandesClientesEnergia.query({
					page : pagingParams.page - 1,
					"internoGrandesClientesId.equals" : $stateParams.id,
					size : 6,
					sort : "fechaEmision,desc",
				}, onSuccessFacturaEnergia, onError);
			}
			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccessFacturaEnergia(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.facturasEnergia = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch
			});
		}
		
		function descargarPdfGrandes(facturaDto) {
			vm.isSending = true;					
			FacturaGrandesPDF.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {			                       
                 FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision+'-factura.pdf');		
                 vm.isSending = false;	        
	        }).catch(function (err) {
				vm.isSending = false;
	        });			
		}
		function onPFDSuccessGrandes(data, headers) {

			var url = URL.createObjectURL(data.response);
			var printwWindow = $window.open(url);
			printwWindow.print();

		}
		function onPDFErrorGrandes(error) {
			AlertService.error(error.data.message);
		}

		function descargarPdfAgua(facturaDto) {
			vm.isSending = true;					
			FacturaAguaPDF.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {			                       
                 FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision+'-factura.pdf');		
                 vm.isSending = false;	        
	        }).catch(function (err) {
				vm.isSending = false;
	        });			
		}
		function onPFDSuccessAgua(data, headers) {

			var url = URL.createObjectURL(data.response);
			var printwWindow = $window.open(url);
			printwWindow.print();

		}
		function onPDFErrorAgua(error) {
			AlertService.error(error.data.message);
		}

		function descargarPdfEnergia(facturaDto) {
			vm.isSending = true;					
			FacturaEnergiaPDF.descargar({"facturaId": facturaDto.id}).$promise.then(function (res) {			                       
                 FileSaver.saveAs(res.response, facturaDto.numeroFactura.toString() + '-'+facturaDto.fechaEmision+'-factura.pdf');		
                 vm.isSending = false;	        
	        }).catch(function (err) {
				vm.isSending = false;
	        });		
		}
		function onPFDSuccessEnergia(data, headers) {

			var url = URL.createObjectURL(data.response);
			var printwWindow = $window.open(url);
			printwWindow.print();

		}
		function onPDFErrorEnergia(error) {
			AlertService.error(error.data.message);
		}

		function search(searchQuery) {
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = '_score';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}
        function pagarFactura(facturaDto) {
            vm.isSending = true;
            ClienteFacturaGrandesClientespagar.post({id : facturaDto.id},onSuccessPagar);
        }
        function onSuccessPagar(data) {        
            $window.open(data.initPoint, '_blank');
        }
	}
})();

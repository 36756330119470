(function() {
    'use strict';
    angular
        .module('coopFrontEndApp')
        .factory('ClienteFactura', ClienteFactura).factory('ClienteFacturaDos', ClienteFacturaDos).factory('ClienteFacturaHistorico', ClienteFacturaHistorico).factory('ClienteFacturaPagar', ClienteFacturaPagar).factory('ClienteFacturaGcPagar', ClienteFacturaGcPagar);

    ClienteFactura.$inject = ['$resource', 'DateUtils'];
    ClienteFacturaDos.$inject = ['$resource', 'DateUtils'];
    ClienteFacturaHistorico.$inject = ['$resource', 'DateUtils'];
    ClienteFacturaPagar.$inject = ['$resource'];
    ClienteFacturaGcPagar.$inject = ['$resource'];

    function ClienteFacturaHistorico ($resource, DateUtils) {
        var resourceUrlHistorico =  'importerv3/' + 'api/cliente/factura/historico/:idFactura';
        return $resource(resourceUrlHistorico, {}, {
            'get': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
    
    function ClienteFacturaPagar ($resource) {
        var resourceUrlPagarFactura =  'coopmercadopago/' + 'api/macro/hogares/pagar';
        return $resource(resourceUrlPagarFactura, {}, {
            'post': {
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
    
        function ClienteFacturaGcPagar ($resource) {
        var resourceUrlPagarFactura =  'coopmercadopago/' + 'api/macro/grandes-clientes/pagar';
        return $resource(resourceUrlPagarFactura, {}, {
            'post': {
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
    
     function ClienteFacturaDos ($resource, DateUtils) {
        var resourceUrl =  'importerv3/' + 'api/cliente/facturasDos/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	  if (data) {
                          data = angular.fromJson(data);
                          data.fechaEmision = DateUtils.convertLocalDateFromServer(data.fechaEmision);
                          data.fechaLecturaActual = DateUtils.convertLocalDateFromServer(data.fechaLecturaActual);
                          data.fechaLecturaAnterior = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnterior);
                          data.fechaLecturaActualSanitario = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualSanitario);
                          data.fechaLecturaAnteriorSanitario = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorSanitario);
                          data.fechaVencimiento = DateUtils.convertLocalDateFromServer(data.fechaVencimiento);
                          data.fechaProximoVencimiento = DateUtils.convertLocalDateFromServer(data.fechaProximoVencimiento);
                          data.fechaPago = DateUtils.convertLocalDateFromServer(data.fechaPago);

                      }
                    return data;
                },
                 isArray: true
            }
        });
    }

    function ClienteFactura ($resource, DateUtils) {
        var resourceUrl =  'importerv3/' + 'api/cliente/facturas/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	  if (data) {
                          data = angular.fromJson(data);
                          data.fechaEmision = DateUtils.convertLocalDateFromServer(data.fechaEmision);
                          data.fechaLecturaActual = DateUtils.convertLocalDateFromServer(data.fechaLecturaActual);
                          data.fechaLecturaAnterior = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnterior);
                          data.fechaLecturaActualSanitario = DateUtils.convertLocalDateFromServer(data.fechaLecturaActualSanitario);
                          data.fechaLecturaAnteriorSanitario = DateUtils.convertLocalDateFromServer(data.fechaLecturaAnteriorSanitario);
                          data.fechaVencimiento = DateUtils.convertLocalDateFromServer(data.fechaVencimiento);
                          data.fechaProximoVencimiento = DateUtils.convertLocalDateFromServer(data.fechaProximoVencimiento);
                          data.fechaPago = DateUtils.convertLocalDateFromServer(data.fechaPago);

                      }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    copy.fechaLecturaActual = DateUtils.convertLocalDateToServer(copy.fechaLecturaActual);
                    copy.fechaLecturaAnterior = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnterior);
                    copy.fechaLecturaActualSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualSanitario);
                    copy.fechaLecturaAnteriorSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorSanitario);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximovencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximovencimiento);
                    copy.deuda = DateUtils.convertLocalDateToServer(copy.deuda);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fechaEmision = DateUtils.convertLocalDateToServer(copy.fechaEmision);
                    copy.fechaLecturaActual = DateUtils.convertLocalDateToServer(copy.fechaLecturaActual);
                    copy.fechaLecturaAnterior = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnterior);
                    copy.fechaLecturaActualSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaActualSanitario);
                    copy.fechaLecturaAnteriorSanitario = DateUtils.convertLocalDateToServer(copy.fechaLecturaAnteriorSanitario);
                    copy.fechaVencimiento = DateUtils.convertLocalDateToServer(copy.fechaVencimiento);
                    copy.fechaProximovencimiento = DateUtils.convertLocalDateToServer(copy.fechaProximovencimiento);
                    copy.deuda = DateUtils.convertLocalDateToServer(copy.deuda);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();

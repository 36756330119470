(function() {
    'use strict';

    angular
        .module('coopFrontEndApp')
        .controller('ConsultasController', ConsultasController);

    ConsultasController.$inject = ['$state'];

    function ConsultasController($state) {
        var vm = this;
        vm.searchInterno = searchInterno;
        vm.searchCliente = searchCliente;
        vm.clear = clear;
        
        
        
        
        function searchInterno(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }

        }
        
        function searchCliente(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }

        }
        
        function clear() {

            vm.currentSearch = null;
 
        }
        
        
    }
})();
